var GetTrackingInfoQuery = /** @class */ (function () {
    function GetTrackingInfoQuery(responseData) {
        Object.defineProperty(this, "responseData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: responseData
        });
    }
    Object.defineProperty(GetTrackingInfoQuery.prototype, "buildResponse", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.responseData;
        }
    });
    Object.defineProperty(GetTrackingInfoQuery, "$name", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: "getTrackingInfoQuery"
    });
    return GetTrackingInfoQuery;
}());
export { GetTrackingInfoQuery };
