import { Money } from "@goinapp/money";
var SubscriptionPaymentPlan = /** @class */ (function () {
    function SubscriptionPaymentPlan(frequency, amount) {
        Object.defineProperty(this, "frequency", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "amount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "fromBEProductMoneyToFEMoney", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (money) {
                if (money.unit === "unit") {
                    return Money.fromNumber(money.value);
                }
                else {
                    return Money.fromCents(money.value);
                }
            }
        });
        this.frequency = frequency;
        this.amount = this.fromBEProductMoneyToFEMoney(amount);
    }
    return SubscriptionPaymentPlan;
}());
export { SubscriptionPaymentPlan };
