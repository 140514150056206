var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Money } from "@goinapp/money";
import { CurrencyType } from "./CurrencyType";
import { MoneyUnit } from "./MoneyUnit";
export var fromBEProductMoneyToFEMoney = function (money) {
    if (money.unit === "unit") {
        return Money.fromNumber(money.value);
    }
    else {
        return Money.fromCents(money.value);
    }
};
export var fromFEMoneyToBEMoney = function (money) {
    var moneyObj = Money.fromSnapshot(money);
    var _a = __read(moneyObj.format().split(" "), 1), amount = _a[0];
    return {
        type: moneyObj.currency.code === "EUR" ? CurrencyType.EURO : CurrencyType.DOLLAR,
        unit: MoneyUnit.UNIT,
        value: Number(amount.replace(",", ".")),
    };
};
