import { COLORS, Spacing, Text } from "@goinapp/ui";
import { observer } from "mobx-react-lite";

export const NOT_FOUND_PAGE = "NotFound";

export const NotFound = observer(() => {
  return (
    <div className="w-screen h-full flex">
      <div className="hidden lg:block">
        <img className="h-screen object-cover max-w-sm xl:max-w-none" src="/login-decoration.png" alt="Goin" />
      </div>

      <div className="flex-1 flex flex-col">
        <div className="flex-1 flex flex-col items-center min-h-screen justify-center">
          <div className="flex flex-col items-center">
            <img width={158} height={59} src="/goin.png" alt="Goin" />
            <Spacing vertical="M" />
            <Text.Header4>{"ERROR 404"}</Text.Header4>
            <Text.Header7 style={{ color: COLORS.grayscale50 }}>{"NO SE HA ENCONTRADO LA PÁGINA"}</Text.Header7>
          </div>
        </div>
      </div>
    </div>
  );
});
