import { User } from "@goinapp/webapp-shared";
import { action, makeObservable, observable } from "mobx";

export class UserStore {
  private static instance: UserStore;

  @observable public user?: User;
  @observable public loading: boolean = true;

  static getInstance(): UserStore {
    if (!this.instance) {
      this.instance = new UserStore();
    }
    return this.instance;
  }

  constructor() {
    makeObservable(this);
  }

  @action
  setUser(user: User) {
    this.user = user;
  }

  @action
  public setLoading(loading: boolean) {
    this.loading = loading;
  }
}
