var UserLoggedInEvent = /** @class */ (function () {
    function UserLoggedInEvent(authData) {
        Object.defineProperty(this, "authData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: authData
        });
    }
    Object.defineProperty(UserLoggedInEvent.prototype, "toPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                authData: this.authData.toPrimitives(),
            };
        }
    });
    Object.defineProperty(UserLoggedInEvent, "$name", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: "userLoggedInEvent"
    });
    return UserLoggedInEvent;
}());
export { UserLoggedInEvent };
