var RefreshUserCommand = /** @class */ (function () {
    function RefreshUserCommand(event) {
        Object.defineProperty(this, "event", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: event
        });
    }
    Object.defineProperty(RefreshUserCommand.prototype, "toPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {};
        }
    });
    Object.defineProperty(RefreshUserCommand, "$name", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: "refreshUserCommand"
    });
    return RefreshUserCommand;
}());
export { RefreshUserCommand };
