import React from "react";
import "./App.css";
import "./assets/fonts/content.styles.css";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { NavigationContainer, Theme } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { OfferRedirectPage, OFFER_REDIRECT_PAGE } from "./pages/offer-redirect/OfferRedirectPage";
import { LoginPage, LOGIN_PAGE } from "./pages/login/LoginPage";
import { COLORS } from "@goinapp/ui";
import { UserProvider } from "./shared/user-context/UserContext";
import {
  EnableAutomaticCashbackPage,
  ENABLE_AUTOMATIC_CASHBACK_PAGE,
} from "./pages/enable-automatic-cashback/EnableAutomaticCashbackPage";
import { constants } from "@goinapp/webapp-shared";
import { NOT_FOUND_PAGE, NotFound } from "./pages/not-found/NotFound";

const Stack = createStackNavigator();

const config = {
  screens: {
    [OFFER_REDIRECT_PAGE]: "offer-redirect/:offerId/:goinTag?",
    [LOGIN_PAGE]: "login",
    [ENABLE_AUTOMATIC_CASHBACK_PAGE]: "enable-automatic-cashback",
    [NOT_FOUND_PAGE]: "*",
  },
};

const linking = {
  prefixes: [constants.WEB_URI],
  config,
};

function App() {
  return (
    <SafeAreaProvider>
      <UserProvider>
        <NavigationContainer
          linking={linking}
          fallback={<h1>Loading Fallback...</h1>}
          theme={
            {
              colors: {
                primary: COLORS.primary,
                background: COLORS.whiteOpacity,
                border: COLORS.grayscale86,
              },
            } as Theme
          }
        >
          <Stack.Navigator screenOptions={{ headerShown: false }}>
            <Stack.Screen name={NOT_FOUND_PAGE} component={NotFound as never} options={{ title: "Goin" }} />
            <Stack.Screen name={OFFER_REDIRECT_PAGE} component={OfferRedirectPage as never} options={{ title: "Goin" }} />
            <Stack.Screen name={LOGIN_PAGE} component={LoginPage as never} options={{ title: "Goin" }} />
            <Stack.Screen
              name={ENABLE_AUTOMATIC_CASHBACK_PAGE}
              component={EnableAutomaticCashbackPage as never}
              options={{ title: "Goin" }}
            />
          </Stack.Navigator>
        </NavigationContainer>
      </UserProvider>
    </SafeAreaProvider>
  );
}

export default App;
