var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { DeviceIdRepository } from "./DeviceIdRepository";
import { BrazeHttpService } from "./BrazeHttpService";
var BrazeTrackingService = /** @class */ (function () {
    function BrazeTrackingService() {
        Object.defineProperty(this, "httpService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "deviceIdRepository", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "retriableEvents", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        this.httpService = BrazeHttpService.getInstance();
        this.deviceIdRepository = DeviceIdRepository.getInstance();
    }
    Object.defineProperty(BrazeTrackingService, "getInstance", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (!this.instance) {
                this.instance = new BrazeTrackingService();
            }
            return this.instance;
        }
    });
    Object.defineProperty(BrazeTrackingService.prototype, "initialize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () { return __generator(this, function (_a) {
                return [2 /*return*/];
            }); });
        }
    });
    Object.defineProperty(BrazeTrackingService.prototype, "createAlias", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a, _b, err_1;
                var _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            _d.trys.push([0, 3, , 4]);
                            _b = (_a = this.httpService).newAlias;
                            _c = {};
                            return [4 /*yield*/, this.deviceIdRepository.get()];
                        case 1: return [4 /*yield*/, _b.apply(_a, [(_c.alias = (_d.sent()).deviceId,
                                    _c)])];
                        case 2:
                            _d.sent();
                            return [3 /*break*/, 4];
                        case 3:
                            err_1 = _d.sent();
                            console.error(err_1);
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(BrazeTrackingService.prototype, "identify", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (user) {
            return __awaiter(this, void 0, void 0, function () {
                var _a, _b, err_2;
                var _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, this.createAlias()];
                        case 1:
                            _d.sent();
                            _d.label = 2;
                        case 2:
                            _d.trys.push([2, 5, , 6]);
                            _b = (_a = this.httpService).identify;
                            _c = {
                                userId: this.getUserId(user)
                            };
                            return [4 /*yield*/, this.deviceIdRepository.get()];
                        case 3: return [4 /*yield*/, _b.apply(_a, [(_c.alias = (_d.sent()).deviceId,
                                    _c)])];
                        case 4:
                            _d.sent();
                            this.retryEvents();
                            return [3 /*break*/, 6];
                        case 5:
                            err_2 = _d.sent();
                            console.error(err_2);
                            return [3 /*break*/, 6];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(BrazeTrackingService.prototype, "track", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event, user, loggedIn) {
            return __awaiter(this, void 0, void 0, function () {
                var trackingInfo, eventName, properties, info, _a, _b, err_3;
                var _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0: return [4 /*yield*/, this.createAlias()];
                        case 1:
                            _d.sent();
                            _d.label = 2;
                        case 2:
                            _d.trys.push([2, 5, , 6]);
                            trackingInfo = event.trackingInfo, eventName = event.eventName, properties = event.properties;
                            info = __rest(trackingInfo, []);
                            _b = (_a = this.httpService).track;
                            _c = {};
                            return [4 /*yield*/, this.deviceIdRepository.get()];
                        case 3: return [4 /*yield*/, _b.apply(_a, [(_c.alias = (_d.sent()).deviceId,
                                    _c.event = eventName,
                                    _c.properties = __assign(__assign({}, properties), { userloggedIn: loggedIn }),
                                    _c)])];
                        case 4:
                            _d.sent();
                            return [3 /*break*/, 6];
                        case 5:
                            err_3 = _d.sent();
                            this.retriableEvents.push({ event: event, loggedIn: loggedIn, user: user });
                            console.error(err_3);
                            return [3 /*break*/, 6];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(BrazeTrackingService.prototype, "getUserId", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (user) {
            if (user) {
                return user.profile.id;
            }
            return undefined;
        }
    });
    Object.defineProperty(BrazeTrackingService.prototype, "retryEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            var events = this.retriableEvents;
            this.retriableEvents = [];
            events.forEach(function (e) { return _this.track(e.event, e.user, e.loggedIn); });
        }
    });
    return BrazeTrackingService;
}());
export { BrazeTrackingService };
