var OfferShownEvent = /** @class */ (function () {
    function OfferShownEvent(offerId) {
        Object.defineProperty(this, "offerId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: offerId
        });
    }
    Object.defineProperty(OfferShownEvent.prototype, "toPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                id: this.offerId,
            };
        }
    });
    Object.defineProperty(OfferShownEvent, "$name", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: "offerShownEvent"
    });
    return OfferShownEvent;
}());
export { OfferShownEvent };
