import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ChainedBackend from "i18next-chained-backend";
import resourcesToBackend from "i18next-resources-to-backend";

export enum LANGUAGES {
  ENGLISH = "en",
  SPANISH = "es",
  CATALAN = "ca",
}

const SUPPORTED_LANGUAGES = [LANGUAGES.ENGLISH, LANGUAGES.SPANISH, LANGUAGES.CATALAN];

i18n
  .use(initReactI18next)
  .use(ChainedBackend)
  .init(
    {
      fallbackLng: {
        an: [LANGUAGES.SPANISH],
        eu: [LANGUAGES.SPANISH],
        gl: [LANGUAGES.SPANISH],
        default: [LANGUAGES.ENGLISH],
      },
      supportedLngs: SUPPORTED_LANGUAGES,
      ns: ["web"],
      react: {
        useSuspense: false,
      },
      backend: {
        backends: [
          // HttpBackend,
          resourcesToBackend((language, _, callback) => {
            switch (language) {
              case LANGUAGES.CATALAN:
                callback(null, require(`../assets/lang/ca/web.json`));
                break;
              case LANGUAGES.ENGLISH:
                callback(null, require(`../assets/lang/en/web.json`));
                break;
              case LANGUAGES.SPANISH:
                callback(null, require(`../assets/lang/es/web.json`));
                break;
              default:
                callback(null, require(`../assets/lang/en/web.json`));
                break;
            }
          }),
        ],
        // backendOptions: [
        //   {
        //     loadPath:
        //       "https://i18n.goin.cloud/export?inline_render=true&language={{lng}}&project_id=375f613b-bc14-437b-920f-9957fb80229d&order_by=key&document_path=web&document_format=json",
        //     customHeaders: {
        //       authorization:
        //         "Bearer hsdySUMQyzHB9mGfnUWTDbEq4pV0In0uUAsUqINc2Y8FQpW4HVbtEEIIn5CYVBk-DmpV3HoABYdzbcGAkEnyTVvKrdwwNA",
        //     },
        //   },
        // ],
      },
    },
    (err) => {
      if (err) {
        console.error(err);
      }
    }
  );

i18n.changeLanguage(navigator.language);

const t = (key: string, data?: any) => {
  return i18n.t(key, data);
};

export { i18n, t, SUPPORTED_LANGUAGES };
