var RewardSourceMatchEvent = /** @class */ (function () {
    function RewardSourceMatchEvent(rewardSourceId) {
        Object.defineProperty(this, "rewardSourceId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: rewardSourceId
        });
    }
    Object.defineProperty(RewardSourceMatchEvent.prototype, "toPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                rewardSourceId: this.rewardSourceId.toPrimitives(),
            };
        }
    });
    Object.defineProperty(RewardSourceMatchEvent, "$name", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: "rewardSourceMatchEvent"
    });
    return RewardSourceMatchEvent;
}());
export { RewardSourceMatchEvent };
