var OfferDetectedModalShownEvent = /** @class */ (function () {
    function OfferDetectedModalShownEvent(offer) {
        Object.defineProperty(this, "offer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: offer
        });
    }
    Object.defineProperty(OfferDetectedModalShownEvent.prototype, "toPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                offer: this.offer.toPrimitives(),
            };
        }
    });
    Object.defineProperty(OfferDetectedModalShownEvent, "$name", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: "offerDetectedModalShownEvent"
    });
    return OfferDetectedModalShownEvent;
}());
export { OfferDetectedModalShownEvent };
