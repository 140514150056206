var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { RewardSourceFilterOperator } from "../domain";
import { CashbackOfferType } from "../domain/cashback-offer";
import { LocalStorageRewardSourceRepository } from "../infrastructure/LocalStorageRewardSourceRepository";
var RewardSourceCriteriaFinder = /** @class */ (function () {
    function RewardSourceCriteriaFinder() {
        Object.defineProperty(this, "rewardOfferRepository", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new LocalStorageRewardSourceRepository()
        });
        Object.defineProperty(this, "CASHBACK_PAGE_SIZE", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 20
        });
        Object.defineProperty(this, "cashbackOffset", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
    }
    Object.defineProperty(RewardSourceCriteriaFinder.prototype, "getPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (filters, reset) {
            return __awaiter(this, void 0, void 0, function () {
                var criteria, rewardOffers;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (reset) {
                                this.reset();
                            }
                            criteria = this.buildCriteria(filters);
                            return [4 /*yield*/, this.rewardOfferRepository.findByCriteria(criteria)];
                        case 1:
                            rewardOffers = _a.sent();
                            this.cashbackOffset = rewardOffers.metadata.offset + rewardOffers.metadata.limit;
                            return [2 /*return*/, {
                                    offers: rewardOffers.offers,
                                    hasNext: rewardOffers.metadata.hasNext,
                                }];
                    }
                });
            });
        }
    });
    Object.defineProperty(RewardSourceCriteriaFinder.prototype, "reset", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.cashbackOffset = 0;
        }
    });
    Object.defineProperty(RewardSourceCriteriaFinder.prototype, "buildCriteria", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (filters) {
            var criteria = {
                limit: this.CASHBACK_PAGE_SIZE,
                offset: this.cashbackOffset,
                filters: [],
                orderBy: "AFFILIATE_FIRST",
            };
            if (filters === null || filters === void 0 ? void 0 : filters.type) {
                criteria.filters.push({
                    field: "type",
                    operator: (filters === null || filters === void 0 ? void 0 : filters.type) === CashbackOfferType.BANK ? RewardSourceFilterOperator.CONTAINS : RewardSourceFilterOperator.EQUAL,
                    value: filters === null || filters === void 0 ? void 0 : filters.type,
                });
            }
            if (filters === null || filters === void 0 ? void 0 : filters.category) {
                criteria.filters.push({
                    field: "category",
                    operator: RewardSourceFilterOperator.EQUAL,
                    value: filters === null || filters === void 0 ? void 0 : filters.category,
                });
            }
            if (filters === null || filters === void 0 ? void 0 : filters.name) {
                criteria.filters.push({
                    field: "name",
                    operator: RewardSourceFilterOperator.CONTAINS,
                    value: filters === null || filters === void 0 ? void 0 : filters.name,
                });
            }
            return criteria;
        }
    });
    return RewardSourceCriteriaFinder;
}());
export { RewardSourceCriteriaFinder };
