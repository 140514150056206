import { TrackingEvent } from "@goinapp/webapp-shared";

export interface BrowserExtensionActivateRewardEventProperties {
  merchant?: string;
  offer_type?: string;
  offer_discount_rate?: string;
  offer_discount_type?: string;
  offer_category?: string;
  offer_condition?: string;
  action_source?: string;
}
export class BrowserExtensionActivateRewardEvent extends TrackingEvent {
  constructor(properties: BrowserExtensionActivateRewardEventProperties) {
    super("Browser Extension Activate Reward", properties);
  }
}
