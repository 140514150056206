var UserLoggedOutEvent = /** @class */ (function () {
    function UserLoggedOutEvent() {
    }
    Object.defineProperty(UserLoggedOutEvent.prototype, "toPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {};
        }
    });
    Object.defineProperty(UserLoggedOutEvent, "$name", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: "userLoggedOutEvent"
    });
    return UserLoggedOutEvent;
}());
export { UserLoggedOutEvent };
