import { Money } from "@goinapp/money";
var RewardOptionFormatter = /** @class */ (function () {
    function RewardOptionFormatter() {
    }
    Object.defineProperty(RewardOptionFormatter, "format", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (option, decimals) {
            if (decimals === void 0) { decimals = 2; }
            if (option.isFixedType()) {
                var multiplier = Math.pow(10, decimals);
                var amount = option.getUnitAmount();
                var roundedAmount = decimals == 0 ? Math.round(amount) : (multiplier * amount) / multiplier;
                return this.formatFixedType(roundedAmount);
            }
            if (option.isPercentageType()) {
                return this.formatPercentageType(option);
            }
            return "";
        }
    });
    Object.defineProperty(RewardOptionFormatter, "formatFixedType", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (roundedAmount) {
            return Money.fromNumber(roundedAmount).format();
        }
    });
    Object.defineProperty(RewardOptionFormatter, "formatPercentageType", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (option) {
            return "".concat(option.getUnitAmount(), "%");
        }
    });
    return RewardOptionFormatter;
}());
export { RewardOptionFormatter };
