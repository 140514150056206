import React, { useEffect, useMemo } from "react";
import { Button, COLORS, Spacing, Text } from "@goinapp/ui";
import { OfferRedirectPageViewModel } from "./OfferRedirectPageViewModel";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useUserContext } from "../../shared/user-context/UserContext";
import { LOGIN_PAGE } from "../login/LoginPage";
import Lottie from "lottie-react-web";
import animation from "./loading-files.json";

interface Props {
  navigation: any;
  route: {
    params: {
      offerId: string;
      dest: string;
      action_source: string;
      goinTag: string;
    };
  };
}

export const OFFER_REDIRECT_PAGE = "OfferRedirectPage";

export const OfferRedirectPage = observer((props: Props) => {
  const { t } = useTranslation("web");
  const viewModel = useMemo(() => OfferRedirectPageViewModel.getInstance(), []);
  const userStore = useUserContext();

  const init = async () => {
    if (!userStore.user && !props.route.params.goinTag) {
      const redirectParam = { page: OFFER_REDIRECT_PAGE, params: { offerId: props.route.params.offerId } };
      props.navigation.navigate(LOGIN_PAGE, {
        redirect: encodeURIComponent(JSON.stringify(redirectParam)),
      });
    }
    await viewModel.init(props.route.params.offerId, props.route.params.dest, props.route.params.goinTag);
    viewModel.setActionSource(props.route.params.action_source);
  };

  useEffect(() => {
    init();
  }, [userStore.user]);

  return (
    <div className="w-screen h-screen flex flex-col justify-between items-center p-4">
      <div>
        <Spacing vertical="XXL" />
        <img width={158} height={59} src="/goin.png" alt="Goin" />
      </div>

      <div className="flex flex-1 flex-col items-center max-w-screen-sm justify-center">
        <Lottie
          width={324}
          height={101}
          options={{
            animationData: animation,
          }}
        />
        <Spacing vertical="S" />
        <div className="text-center">
          <Text.Header2
            style={{
              color: COLORS.grayscale12,
            }}
          >
            {t("offer.redirect.page.title")}
            <Text.Header2
              style={{
                color: COLORS.link,
              }}
            >
              <br />
              {" " + (viewModel.offer?.name || t("offer.redirect.page.loading.offer.name"))}
            </Text.Header2>
          </Text.Header2>

          <Spacing vertical="S" />

          <Text.Header5
            style={{
              color: COLORS.grayscale50,
            }}
          >
            {t("offer.redirect.page.subtitle.begin") + " "}
            <Text.Header5
              style={{
                color: COLORS.link,
              }}
            >
              {t("offer.redirect.page.subtitle.end", { rate: viewModel.getFormattedRewardOption() })}
            </Text.Header5>
          </Text.Header5>
        </div>
        <Spacing vertical="L" />
        <div className="flex ">
          <Button
            loading={!viewModel.offerUrl}
            style={{ paddingHorizontal: 100 }}
            size="L"
            onPress={() => {
              viewModel.directNavigation();
            }}
          >
            {t("offer.redirect.page.button")}
          </Button>
        </div>
      </div>
    </div>
  );
});
