var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Money } from "@goinapp/money";
import { BankOffer } from "../bank-offer/BankOffer";
import { RewardOption } from "../../RewardOption";
import { TieredRewardOptions } from "../TieredRewardOptions";
var BankLoyaltyOffer = /** @class */ (function (_super) {
    __extends(BankLoyaltyOffer, _super);
    function BankLoyaltyOffer(id, type, name, description, category, image, brandLogo, startDate, endDate, legalTerms, applicationChannel, applicationUrl, maxCashbacksPerConsumer, cashbackRate, maxAmount, minAmount, amountToReward, mainRewardOption, tieredRewardOptions, active, shown) {
        var _this = _super.call(this, id, type, name, description, category, image, brandLogo, startDate, endDate, legalTerms, applicationChannel, applicationUrl, maxCashbacksPerConsumer, cashbackRate, maxAmount, minAmount, mainRewardOption, tieredRewardOptions, active, shown) || this;
        Object.defineProperty(_this, "amountToReward", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: amountToReward
        });
        return _this;
    }
    Object.defineProperty(BankLoyaltyOffer.prototype, "isInProgress", {
        get: function () {
            return this.amountToReward.progress > 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BankLoyaltyOffer.prototype, "getAmountToReward", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.amountToReward;
        }
    });
    Object.defineProperty(BankLoyaltyOffer.prototype, "getProgress", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return Money.fromNumber(this.amountToReward.progress);
        }
    });
    Object.defineProperty(BankLoyaltyOffer.prototype, "getTotal", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return Money.fromNumber(this.amountToReward.total);
        }
    });
    Object.defineProperty(BankLoyaltyOffer.prototype, "progressPercentage", {
        get: function () {
            return Math.abs((this.amountToReward.progress * 100) / this.amountToReward.total);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BankLoyaltyOffer, "fromPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (primitives) {
            return new BankLoyaltyOffer(primitives.id, primitives.type, primitives.name, primitives.description, primitives.category, primitives.image, primitives.brandLogo, primitives.startDate, primitives.endDate, primitives.legalTerms, primitives.applicationChannel, primitives.applicationUrl, primitives.maxCashbacksPerConsumer, primitives.cashbackRate, primitives.maxAmount, primitives.minAmount, primitives.amountToReward, RewardOption.fromPrimitives(primitives.mainRewardOption), TieredRewardOptions.fromPrimitives(primitives.tieredRewardOptions), primitives.active, primitives.shown);
        }
    });
    Object.defineProperty(BankLoyaltyOffer.prototype, "toPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                id: this.id,
                type: this.type,
                name: this.name,
                description: this.description,
                category: this.category,
                image: this.image,
                brandLogo: this.brandLogo,
                startDate: this.startDate,
                endDate: this.endDate,
                legalTerms: this.legalTerms,
                applicationChannel: this.applicationChannel,
                applicationUrl: this.applicationUrl,
                maxCashbacksPerConsumer: this.maxCashbacksPerConsumer,
                cashbackRate: this.cashbackRate,
                maxAmount: this.maxAmount,
                minAmount: this.minAmount,
                amountToReward: this.amountToReward,
                mainRewardOption: this.mainRewardOption.toPrimitives(),
                tieredRewardOptions: this.tieredRewardOptions.toPrimitives(),
                active: this.active,
                shown: this.shown,
            };
        }
    });
    return BankLoyaltyOffer;
}(BankOffer));
export { BankLoyaltyOffer };
