import { SubscriptionPaymentPlan } from "./SubscriptionPaymentPlan";
var Subscription = /** @class */ (function () {
    function Subscription(primitives) {
        Object.defineProperty(this, "isActive", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isRenewable", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "endDate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "tier", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "currentInterval", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_paymentPlan", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.isActive = primitives.isActive;
        this.isRenewable = primitives.isRenewable;
        this.endDate = new Date(primitives.endDate);
        this.tier = primitives.tier;
        this.currentInterval = {
            startDate: new Date(primitives.currentInterval.startDate),
            endDate: new Date(primitives.currentInterval.endDate),
        };
        this._paymentPlan = new SubscriptionPaymentPlan(primitives.paymentPlan.frequency, primitives.paymentPlan.amount);
    }
    Object.defineProperty(Subscription, "fromPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (primitives) {
            return new Subscription(primitives);
        }
    });
    Object.defineProperty(Subscription.prototype, "paymentPlanAmount", {
        get: function () {
            return this._paymentPlan.amount;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Subscription.prototype, "paymentPlanFrequencyType", {
        get: function () {
            return this._paymentPlan.frequency.type;
        },
        enumerable: false,
        configurable: true
    });
    return Subscription;
}());
export { Subscription };
