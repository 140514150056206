import CryptoJS from "crypto-js";
var CashbackOfferIdEncrypter = /** @class */ (function () {
    function CashbackOfferIdEncrypter() {
    }
    Object.defineProperty(CashbackOfferIdEncrypter, "decrypt", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (value) {
            return this.decryptWithAES(value);
        }
    });
    Object.defineProperty(CashbackOfferIdEncrypter, "encrypt", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (value) {
            return this.encryptWithAES(value);
        }
    });
    Object.defineProperty(CashbackOfferIdEncrypter, "passphrase", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: "offerIdPassphrase"
    });
    Object.defineProperty(CashbackOfferIdEncrypter, "encryptWithAES", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: function (string) {
            var cipher = CryptoJS.AES.encrypt(string, CashbackOfferIdEncrypter.passphrase).toString();
            return cipher.replace(/\+/g, "p1L2u3S").replace(/\//g, "s1L2a3S4h").replace(/=/g, "e1Q2u3A4l");
        }
    });
    Object.defineProperty(CashbackOfferIdEncrypter, "decryptWithAES", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: function (string) {
            var cipher = string
                .replace(/p1L2u3S/g, "+")
                .replace(/s1L2a3S4h/g, "/")
                .replace(/e1Q2u3A4l/g, "=");
            var bytes = CryptoJS.AES.decrypt(cipher, CashbackOfferIdEncrypter.passphrase);
            var originalText = bytes.toString(CryptoJS.enc.Utf8);
            return originalText;
        }
    });
    return CashbackOfferIdEncrypter;
}());
export { CashbackOfferIdEncrypter };
