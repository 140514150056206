var TrackEventCommand = /** @class */ (function () {
    function TrackEventCommand(event, trackingInfo) {
        Object.defineProperty(this, "event", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: event
        });
        Object.defineProperty(this, "trackingInfo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: trackingInfo
        });
    }
    Object.defineProperty(TrackEventCommand.prototype, "toPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                event: this.event.toPrimitives(),
                trackingInfo: this.trackingInfo,
            };
        }
    });
    Object.defineProperty(TrackEventCommand, "$name", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: "trackEventCommand"
    });
    return TrackEventCommand;
}());
export { TrackEventCommand };
