var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { constants } from "../../constants";
var ChromeBus = /** @class */ (function () {
    function ChromeBus() {
        Object.defineProperty(this, "handlers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
    }
    Object.defineProperty(ChromeBus, "getQueryBus", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.getInstance();
        }
    });
    Object.defineProperty(ChromeBus, "getCommandBus", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.getInstance();
        }
    });
    Object.defineProperty(ChromeBus, "getInstance", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (!this.instance) {
                this.instance = new ChromeBus();
            }
            return this.instance;
        }
    });
    Object.defineProperty(ChromeBus.prototype, "handle", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (message, sender, sendResponse) {
            var handler = this.handlers[message.topic];
            if (handler) {
                handler({ content: message.content, sender: sender }).then(function (response) {
                    sendResponse(response);
                });
            }
            return true;
        }
    });
    Object.defineProperty(ChromeBus.prototype, "subscribe", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (topic, handler) {
            this.handlers[topic] = handler;
        }
    });
    Object.defineProperty(ChromeBus.prototype, "get", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (topic, content, options) {
            return __awaiter(this, void 0, void 0, function () {
                var tab, _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 5, , 6]);
                            if (!(options === null || options === void 0 ? void 0 : options.toActiveTab)) return [3 /*break*/, 3];
                            return [4 /*yield*/, this.getActiveTab()];
                        case 1:
                            tab = _b.sent();
                            if (!(tab === null || tab === void 0 ? void 0 : tab.id)) return [3 /*break*/, 3];
                            return [4 /*yield*/, chrome.tabs.sendMessage(tab.id, { topic: topic, content: content })];
                        case 2: return [2 /*return*/, _b.sent()];
                        case 3: return [4 /*yield*/, chrome.runtime.sendMessage({ topic: topic, content: content })];
                        case 4: return [2 /*return*/, _b.sent()];
                        case 5:
                            _a = _b.sent();
                            return [2 /*return*/, {}];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(ChromeBus.prototype, "publish", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (topic, content, options) {
            return __awaiter(this, void 0, void 0, function () {
                var tab;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(options === null || options === void 0 ? void 0 : options.toExtension)) return [3 /*break*/, 2];
                            return [4 /*yield*/, chrome.runtime.sendMessage(constants.extensionId.chrome, { topic: topic, content: content })];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                        case 2:
                            if (!(options === null || options === void 0 ? void 0 : options.toActiveTab)) return [3 /*break*/, 5];
                            return [4 /*yield*/, this.getActiveTab()];
                        case 3:
                            tab = _a.sent();
                            if (!(tab === null || tab === void 0 ? void 0 : tab.id)) return [3 /*break*/, 5];
                            return [4 /*yield*/, chrome.tabs.sendMessage(tab.id, { topic: topic, content: content })];
                        case 4:
                            _a.sent();
                            return [2 /*return*/];
                        case 5: return [4 /*yield*/, chrome.runtime.sendMessage({ topic: topic, content: content })];
                        case 6:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(ChromeBus.prototype, "execute", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (topic, content, options) {
            return __awaiter(this, void 0, void 0, function () {
                var a, tab;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(options === null || options === void 0 ? void 0 : options.toExtension)) return [3 /*break*/, 2];
                            return [4 /*yield*/, chrome.runtime.sendMessage(constants.extensionId.chrome, { topic: topic, content: content })];
                        case 1:
                            a = _a.sent();
                            return [2 /*return*/];
                        case 2:
                            if (!(options === null || options === void 0 ? void 0 : options.toActiveTab)) return [3 /*break*/, 5];
                            return [4 /*yield*/, this.getActiveTab()];
                        case 3:
                            tab = _a.sent();
                            if (!(tab === null || tab === void 0 ? void 0 : tab.id)) return [3 /*break*/, 5];
                            return [4 /*yield*/, chrome.tabs.sendMessage(tab.id, { topic: topic, content: content })];
                        case 4:
                            _a.sent();
                            _a.label = 5;
                        case 5: return [4 /*yield*/, chrome.runtime.sendMessage({ topic: topic, content: content })];
                        case 6:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(ChromeBus.prototype, "getActiveTab", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var tabs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, chrome.tabs.query({ active: true, currentWindow: true })];
                        case 1:
                            tabs = _a.sent();
                            return [2 /*return*/, tabs[0]];
                    }
                });
            });
        }
    });
    return ChromeBus;
}());
export { ChromeBus };
