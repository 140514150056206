var OpenPopupCommand = /** @class */ (function () {
    function OpenPopupCommand(deeplink) {
        Object.defineProperty(this, "deeplink", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: deeplink
        });
    }
    Object.defineProperty(OpenPopupCommand.prototype, "toPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                deeplink: this.deeplink,
            };
        }
    });
    Object.defineProperty(OpenPopupCommand, "$name", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: "OpenPopupCommand"
    });
    return OpenPopupCommand;
}());
export { OpenPopupCommand };
