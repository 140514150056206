var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { CashbackOfferIdEncrypter } from "./cashback-offer/CashbackOfferIdEncrypter";
import { ChromeExtensionLocalStorageRepository } from "../../../utils/local-storage-repository/ChromeExtensionLocalStorageRepository";
import { AffiliateOffer, BankLoyaltyOffer, BankOffer, Giftcard, isAffiliatePrimitives, isBankLoyaltyParams, RewardSource, } from "../domain";
import { HttpRewardSourceService } from "./HttpRewardSourceService";
import { RewardSourceWebDomain } from "../domain/RewardSourceWebDomain";
import { BrowserDetector } from "../../../utils";
var LocalStorageRewardSourceRepository = /** @class */ (function () {
    function LocalStorageRewardSourceRepository() {
        Object.defineProperty(this, "CACHE_DURATION", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 2 * 60 * 60 * 1000
        }); // 2 hours
        Object.defineProperty(this, "webDomainsKeyStorage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: "web-domains"
        });
        Object.defineProperty(this, "giftcardKeyStorage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (slug) { return "giftcard-".concat(slug); }
        });
        // TODO: cashback-offer-${decryptedId}
        Object.defineProperty(this, "cashbackOfferKeyStorage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (id) { return "offer-".concat(id); }
        });
        Object.defineProperty(this, "rewardSourceService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new HttpRewardSourceService()
        });
    }
    Object.defineProperty(LocalStorageRewardSourceRepository.prototype, "findWebDomains", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var result, webDomains;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.cleanup()];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, ChromeExtensionLocalStorageRepository.findOne(this.webDomainsKeyStorage)];
                        case 2:
                            result = _a.sent();
                            webDomains = (result === null || result === void 0 ? void 0 : result.data.map(function (element) { return _this.webDomainToDomain(element); })) || [];
                            if (!(webDomains.length === 0)) return [3 /*break*/, 5];
                            return [4 /*yield*/, this.rewardSourceService.findWebDomains()];
                        case 3:
                            webDomains = _a.sent();
                            return [4 /*yield*/, this.saveWebDomains(webDomains)];
                        case 4:
                            _a.sent();
                            _a.label = 5;
                        case 5: return [2 /*return*/, webDomains];
                    }
                });
            });
        }
    });
    Object.defineProperty(LocalStorageRewardSourceRepository.prototype, "findByCriteria", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (criteria) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.rewardSourceService.findMany(criteria)];
                        case 1: return [2 /*return*/, _a.sent()];
                    }
                });
            });
        }
    });
    Object.defineProperty(LocalStorageRewardSourceRepository.prototype, "find", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (id) {
            return __awaiter(this, void 0, void 0, function () {
                var _a, _b, _c, _d;
                return __generator(this, function (_e) {
                    switch (_e.label) {
                        case 0:
                            if (!id.isCashback()) return [3 /*break*/, 2];
                            _b = (_a = RewardSource).newCashback;
                            return [4 /*yield*/, this.findCashbackOffer(id.value)];
                        case 1: return [2 /*return*/, _b.apply(_a, [_e.sent()])];
                        case 2:
                            if (!id.isGiftcard()) return [3 /*break*/, 4];
                            _d = (_c = RewardSource).newGiftcard;
                            return [4 /*yield*/, this.findGiftcard(id.value)];
                        case 3: return [2 /*return*/, _d.apply(_c, [_e.sent()])];
                        case 4: return [2 /*return*/, null];
                    }
                });
            });
        }
    });
    Object.defineProperty(LocalStorageRewardSourceRepository.prototype, "findCashbackOffer", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (id) {
            return __awaiter(this, void 0, void 0, function () {
                var decryptedId, offerStorageResponse, data, offer;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            decryptedId = CashbackOfferIdEncrypter.decrypt(id);
                            return [4 /*yield*/, this.cleanup()];
                        case 1:
                            _a.sent();
                            if (!BrowserDetector.isChrome()) return [3 /*break*/, 3];
                            return [4 /*yield*/, ChromeExtensionLocalStorageRepository.findOne(this.cashbackOfferKeyStorage(decryptedId))];
                        case 2:
                            offerStorageResponse = _a.sent();
                            if (offerStorageResponse) {
                                data = offerStorageResponse.data;
                                return [2 /*return*/, this.toDomain(data)];
                            }
                            _a.label = 3;
                        case 3: return [4 /*yield*/, this.rewardSourceService.findCashbackOffer(id)];
                        case 4:
                            offer = _a.sent();
                            return [4 /*yield*/, this.saveCashbackOffer(offer)];
                        case 5:
                            _a.sent();
                            return [2 /*return*/, offer];
                    }
                });
            });
        }
    });
    Object.defineProperty(LocalStorageRewardSourceRepository.prototype, "findGiftcard", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (id) {
            return __awaiter(this, void 0, void 0, function () {
                var giftcardStorageResponse, giftcard;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.cleanup()];
                        case 1:
                            _a.sent();
                            if (!BrowserDetector.isChrome()) return [3 /*break*/, 3];
                            return [4 /*yield*/, ChromeExtensionLocalStorageRepository.findOne(this.giftcardKeyStorage(id))];
                        case 2:
                            giftcardStorageResponse = _a.sent();
                            if (giftcardStorageResponse) {
                                return [2 /*return*/, Giftcard.fromPrimitives(giftcardStorageResponse.data)];
                            }
                            _a.label = 3;
                        case 3: return [4 /*yield*/, this.rewardSourceService.findGiftcard(id)];
                        case 4:
                            giftcard = _a.sent();
                            return [4 /*yield*/, this.saveGiftcard(giftcard)];
                        case 5:
                            _a.sent();
                            return [2 /*return*/, giftcard];
                    }
                });
            });
        }
    });
    Object.defineProperty(LocalStorageRewardSourceRepository.prototype, "saveCashbackOffer", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (offer) {
            return __awaiter(this, void 0, void 0, function () {
                var decryptedId;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            decryptedId = CashbackOfferIdEncrypter.decrypt(offer.id);
                            if (!BrowserDetector.isChrome()) return [3 /*break*/, 2];
                            return [4 /*yield*/, ChromeExtensionLocalStorageRepository.updateOrCreate(this.cashbackOfferKeyStorage(decryptedId), {
                                    data: offer.toPrimitives(),
                                    savedOn: Date.now(),
                                })];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(LocalStorageRewardSourceRepository.prototype, "saveGiftcard", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (giftcard) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!BrowserDetector.isChrome()) return [3 /*break*/, 2];
                            return [4 /*yield*/, ChromeExtensionLocalStorageRepository.updateOrCreate(this.giftcardKeyStorage(giftcard.name), {
                                    data: giftcard.toPrimitives(),
                                    savedOn: Date.now(),
                                })];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(LocalStorageRewardSourceRepository.prototype, "saveWebDomains", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (data) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!BrowserDetector.isChrome()) return [3 /*break*/, 2];
                            return [4 /*yield*/, ChromeExtensionLocalStorageRepository.updateOrCreate(this.webDomainsKeyStorage, {
                                    data: data.map(function (item) { return item.toPrimitives(); }),
                                    savedOn: Date.now(),
                                })];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(LocalStorageRewardSourceRepository.prototype, "clearCache", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var all, allKeys;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!BrowserDetector.isChrome()) return [3 /*break*/, 3];
                            return [4 /*yield*/, ChromeExtensionLocalStorageRepository.findAll()];
                        case 1:
                            all = _a.sent();
                            if (!all) return [3 /*break*/, 3];
                            allKeys = Object.keys(all);
                            return [4 /*yield*/, ChromeExtensionLocalStorageRepository.delete(allKeys)];
                        case 2:
                            _a.sent();
                            _a.label = 3;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(LocalStorageRewardSourceRepository.prototype, "cleanup", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var cutoff, all, expiredKeys;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!BrowserDetector.isChrome()) {
                                return [2 /*return*/];
                            }
                            cutoff = Date.now() - this.CACHE_DURATION;
                            return [4 /*yield*/, ChromeExtensionLocalStorageRepository.findAll()];
                        case 1:
                            all = _a.sent();
                            if (!all) return [3 /*break*/, 3];
                            expiredKeys = Object.keys(all).filter(function (k) { return all[k].savedOn < cutoff; });
                            return [4 /*yield*/, ChromeExtensionLocalStorageRepository.delete(expiredKeys)];
                        case 2:
                            _a.sent();
                            _a.label = 3;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(LocalStorageRewardSourceRepository.prototype, "toDomain", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (primitives) {
            if (isAffiliatePrimitives(primitives)) {
                return AffiliateOffer.fromPrimitives(primitives);
            }
            if (isBankLoyaltyParams(primitives)) {
                return BankLoyaltyOffer.fromPrimitives(primitives);
            }
            return BankOffer.fromPrimitives(primitives);
        }
    });
    Object.defineProperty(LocalStorageRewardSourceRepository.prototype, "webDomainToDomain", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (primitives) {
            return RewardSourceWebDomain.fromPrimitives(primitives);
        }
    });
    return LocalStorageRewardSourceRepository;
}());
export { LocalStorageRewardSourceRepository };
