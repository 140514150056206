var OfferActivatedEvent = /** @class */ (function () {
    function OfferActivatedEvent(offerId) {
        Object.defineProperty(this, "offerId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: offerId
        });
    }
    Object.defineProperty(OfferActivatedEvent.prototype, "toPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                id: this.offerId,
            };
        }
    });
    Object.defineProperty(OfferActivatedEvent, "$name", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: "offerActivatedEvent"
    });
    return OfferActivatedEvent;
}());
export { OfferActivatedEvent };
