import { RewardSourceId } from "./RewardSourceId";
var RewardSourceWebDomain = /** @class */ (function () {
    function RewardSourceWebDomain(id, domain) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: id
        });
        Object.defineProperty(this, "domain", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: domain
        });
    }
    Object.defineProperty(RewardSourceWebDomain, "fromPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (primitives) {
            return new RewardSourceWebDomain(new RewardSourceId(primitives.id.value, primitives.id.type), primitives.domain);
        }
    });
    Object.defineProperty(RewardSourceWebDomain.prototype, "isCashback", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.id.isCashback();
        }
    });
    Object.defineProperty(RewardSourceWebDomain.prototype, "isGiftcard", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.id.isGiftcard();
        }
    });
    Object.defineProperty(RewardSourceWebDomain.prototype, "toPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                id: this.id.toPrimitives(),
                domain: this.domain,
            };
        }
    });
    return RewardSourceWebDomain;
}());
export { RewardSourceWebDomain };
