var User = /** @class */ (function () {
    function User(profile, bankingConfiguration, hasCashbackActive, subscription) {
        Object.defineProperty(this, "profile", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: profile
        });
        Object.defineProperty(this, "bankingConfiguration", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: bankingConfiguration
        });
        Object.defineProperty(this, "hasCashbackActive", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: hasCashbackActive
        });
        Object.defineProperty(this, "subscription", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: subscription
        });
    }
    Object.defineProperty(User.prototype, "hasCashbackAndBankActive", {
        get: function () {
            return this.hasCashbackActive && !!this.bankingConfiguration.bank && !!this.bankingConfiguration.ibanLastDigits;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(User, "fromPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (userPrimitives) {
            return new User(userPrimitives.profile, userPrimitives.bankConfiguration, userPrimitives.hasCashbackActive, userPrimitives.subscription);
        }
    });
    Object.defineProperty(User.prototype, "toPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                profile: this.profile,
                bankConfiguration: this.bankingConfiguration,
                hasCashbackActive: this.hasCashbackActive,
                subscription: this.subscription,
            };
        }
    });
    return User;
}());
export { User };
