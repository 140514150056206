var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as Sentry from "@sentry/browser";
import { constants } from "../../constants";
import { Platforms } from "../../utils";
import { UserFinder } from "../../user";
var SentryMonitoringService = /** @class */ (function () {
    function SentryMonitoringService() {
    }
    Object.defineProperty(SentryMonitoringService, "getInstance", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (!this.instance) {
                this.instance = new SentryMonitoringService();
            }
            return this.instance;
        }
    });
    Object.defineProperty(SentryMonitoringService.prototype, "init", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (platform) {
            return __awaiter(this, void 0, void 0, function () {
                var integrations, userDetail;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (platform === Platforms.BROWSER) {
                                integrations = [new Sentry.BrowserTracing()];
                            }
                            Sentry.init({
                                dsn: constants.SENTRY_DSN,
                                environment: process.env.NODE_ENV,
                                release: this.releaseVersion,
                                integrations: integrations,
                                beforeSend: function (event) { return __awaiter(_this, void 0, void 0, function () {
                                    var userDetail_1;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                if (!event.user) return [3 /*break*/, 2];
                                                return [4 /*yield*/, this.attemptToGetUserDetail()];
                                            case 1:
                                                userDetail_1 = _a.sent();
                                                event.user.email = userDetail_1.email;
                                                event.user.phone = userDetail_1.phone;
                                                _a.label = 2;
                                            case 2: return [2 /*return*/, event];
                                        }
                                    });
                                }); },
                            });
                            return [4 /*yield*/, this.attemptToGetUserDetail()];
                        case 1:
                            userDetail = _a.sent();
                            Sentry.setUser(userDetail);
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(SentryMonitoringService.prototype, "identify", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (email) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    Sentry.setUser({ email: email });
                    return [2 /*return*/];
                });
            });
        }
    });
    Object.defineProperty(SentryMonitoringService.prototype, "reset", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    Sentry.setUser(null);
                    return [2 /*return*/];
                });
            });
        }
    });
    Object.defineProperty(SentryMonitoringService.prototype, "captureException", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (err) {
            Sentry.captureException(err);
        }
    });
    Object.defineProperty(SentryMonitoringService.prototype, "attemptToGetUserDetail", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var email, phone, user, e_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            email = "unknown";
                            phone = "unknown";
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, new UserFinder().find()];
                        case 2:
                            user = _a.sent();
                            (user === null || user === void 0 ? void 0 : user.profile.email) && (email = user === null || user === void 0 ? void 0 : user.profile.email);
                            (user === null || user === void 0 ? void 0 : user.profile.phone) && (phone = user === null || user === void 0 ? void 0 : user.profile.phone.number.toString());
                            return [3 /*break*/, 4];
                        case 3:
                            e_1 = _a.sent();
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/, {
                                email: email,
                                phone: phone,
                            }];
                    }
                });
            });
        }
    });
    Object.defineProperty(SentryMonitoringService.prototype, "releaseVersion", {
        get: function () {
            var releaseVersion;
            try {
                releaseVersion = chrome.runtime.getManifest().version;
            }
            catch (e) { }
            return releaseVersion;
        },
        enumerable: false,
        configurable: true
    });
    return SentryMonitoringService;
}());
export { SentryMonitoringService };
