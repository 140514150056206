import { COLORS, Icon, SPACING, Spacing, Text } from "@goinapp/ui";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "../../components/QRCode";
import { useUserContext } from "../../shared/user-context/UserContext";
import { LoginPageViewModel } from "./LoginPageViewModel";

interface Props {
  navigation: any;
  route: {
    params: {
      redirect?: string;
    };
  };
}

interface Redirect {
  page: string;
  params: object;
}

export const LOGIN_PAGE = "LoginPage";

export const LoginPage = observer((props: Props) => {
  const { t } = useTranslation("web");
  const viewModel = useMemo(() => LoginPageViewModel.getInstance(), []);
  const userContext = useUserContext();

  const init = async () => {
    await viewModel.init(userContext.user);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (viewModel.isAuthorized && props.route.params && props.route.params.redirect) {
      const redirect = JSON.parse(decodeURIComponent(props.route.params.redirect!)) as Redirect;
      props.navigation.navigate(redirect.page, redirect.params);
    }
  }, [viewModel.isAuthorized]);

  return (
    <div className="w-screen h-full flex">
      <div className="hidden xl:block">
        <img className="h-screen object-cover max-w-sm xl:max-w-none" src="/login-decoration.png" alt="Goin" />
      </div>

      <div className="flex-1 flex flex-col">
        <div className="flex-1 flex flex-col items-center min-h-screen justify-center">
          <div className="flex flex-col items-center pt-10">
            <img width={158} height={59} src="/goin.png" alt="Goin" />
            <Spacing vertical="M" />
            {viewModel.user ? (
              <div className="flex flex-col items-center">
                <Text.Header4>{t("login.page.success.title")}</Text.Header4>
                <Text.Header4 style={{ color: COLORS.primary }}>{viewModel.user.profile.email}</Text.Header4>
              </div>
            ) : (
              <>
                <Text.Header4>{t("login.page.title")}</Text.Header4>
                <Text.Header7 style={{ color: COLORS.grayscale50 }}>{t("login.page.subtitle")}</Text.Header7>
              </>
            )}
          </div>
          {!viewModel.user && <LogInQR isAuthorized={viewModel.isAuthorized} qrDeeplink={viewModel.qrDeeplink} />}
        </div>
      </div>
    </div>
  );
});

const LogInQR = ({ isAuthorized, qrDeeplink }: { isAuthorized: boolean; qrDeeplink?: string }) => {
  const { t } = useTranslation("web");

  return (
    <>
      {isAuthorized ? (
        <div className="flex mt-28 flex-col items-center">
          <img width={284} height={247} src="/check.png" alt="Success" />
          <Text.Header2 style={{ color: COLORS.success }}>{t("login.page.success.title")}</Text.Header2>
          <Text.Header7 style={{ color: COLORS.grayscale50 }}>{t("login.page.success.subtitle")}</Text.Header7>
        </div>
      ) : (
        <div className="flex gap-16 mt-28 items-center flex-col lg:flex-row">
          <div className="w-96 order-last lg:order-first">
            <div className="flex mb-5 items-center">
              <Icon style={{ justifyContent: "flex-start" }} icon="first" color={COLORS.primary} shape="SIMPLE" size="XL" />
              <Spacing horizontal="XS" />
              <Text.Header6>{t("login.page.instruction.1")}</Text.Header6>
            </div>
            <div className="flex mb-5 items-center">
              <Icon style={{ justifyContent: "flex-start" }} icon="second" color={COLORS.primary} shape="SIMPLE" size="XL" />
              <Spacing horizontal="XS" />
              <Text.Header6>{t("login.page.instruction.2")}</Text.Header6>
            </div>
            <div className="flex mb-5 items-center">
              <Icon style={{ justifyContent: "flex-start" }} icon="third" color={COLORS.primary} shape="SIMPLE" size="XL" />
              <Spacing horizontal="XS" />
              <Text.Header6>{t("login.page.instruction.3")}</Text.Header6>
            </div>
            <div className="flex mb-5 items-center">
              <Icon style={{ justifyContent: "flex-start" }} icon="fourth" color={COLORS.primary} shape="SIMPLE" size="XL" />
              <Spacing horizontal="XS" />
              <Text.Header6>{t("login.page.instruction.4")}</Text.Header6>
            </div>
          </div>
          <div className="w-80 flex items-center flex-col justify-center">
            <div style={{ backgroundColor: COLORS.grayscale96, padding: SPACING.M, borderRadius: SPACING.S }} className="">
              {qrDeeplink && <QRCode data={qrDeeplink} width={224} height={224} />}
            </div>

            <div className="text-center w-48 mt-3">
              <Text.Body style={{ color: COLORS.grayscale50 }}>{t("login.page.qr")}</Text.Body>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
