var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { CashbackOffer } from "../CashbackOffer";
import { RewardOption } from "../../RewardOption";
import { TieredRewardOptions } from "../TieredRewardOptions";
var AffiliateOffer = /** @class */ (function (_super) {
    __extends(AffiliateOffer, _super);
    function AffiliateOffer(id, type, name, description, category, image, brandLogo, startDate, endDate, legalTerms, applicationChannel, applicationUrl, rewardOptions, mainRewardOption, tieredRewardOptions, deepLinkUrl, active, shown) {
        var _this = _super.call(this, id, type, name, description, category, image, brandLogo, startDate, endDate, legalTerms, applicationChannel, applicationUrl, mainRewardOption, tieredRewardOptions, active, shown) || this;
        Object.defineProperty(_this, "rewardOptions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: rewardOptions
        });
        Object.defineProperty(_this, "deepLinkUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: deepLinkUrl
        });
        return _this;
    }
    Object.defineProperty(AffiliateOffer.prototype, "affiliateLink", {
        get: function () {
            return this.deepLinkUrl;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AffiliateOffer.prototype, "getRewardOptions", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.rewardOptions.filter(function (option) {
                return option.isAmountGreaterThanZero();
            });
        }
    });
    Object.defineProperty(AffiliateOffer.prototype, "storeUrl", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.deepLinkUrl || this.applicationUrl || "";
        }
    });
    Object.defineProperty(AffiliateOffer, "fromPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (primitives) {
            return new AffiliateOffer(primitives.id, primitives.type, primitives.name, primitives.description, primitives.category, primitives.image, primitives.brandLogo, primitives.startDate, primitives.endDate, primitives.legalTerms, primitives.applicationChannel, primitives.applicationUrl, primitives.rewardOptions.map(function (option) { return RewardOption.fromPrimitives(option); }), RewardOption.fromPrimitives(primitives.mainRewardOption), TieredRewardOptions.fromPrimitives(primitives.tieredRewardOptions), primitives.deepLinkUrl, primitives.active, primitives.shown);
        }
    });
    Object.defineProperty(AffiliateOffer.prototype, "toPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                id: this.id,
                type: this.type,
                name: this.name,
                description: this.description,
                category: this.category,
                image: this.image,
                brandLogo: this.brandLogo,
                startDate: this.startDate,
                endDate: this.endDate,
                legalTerms: this.legalTerms,
                applicationChannel: this.applicationChannel,
                applicationUrl: this.applicationUrl,
                active: this.active,
                rewardOptions: this.rewardOptions.map(function (option) { return option.toPrimitives(); }),
                mainRewardOption: this.mainRewardOption.toPrimitives(),
                tieredRewardOptions: this.tieredRewardOptions.toPrimitives(),
                deepLinkUrl: this.deepLinkUrl,
                shown: this.shown,
            };
        }
    });
    return AffiliateOffer;
}(CashbackOffer));
export { AffiliateOffer };
