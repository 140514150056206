var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// TODO: workaround to use same properties as mixpanel, implement our own way.
// @ts-ignore
import { _ } from "mixpanel-browser/src/utils";
var TrackingInfoGetter = /** @class */ (function () {
    function TrackingInfoGetter() {
    }
    Object.defineProperty(TrackingInfoGetter, "get", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var extension_version;
            try {
                // TODO: this should not be here, doesnt work in certain web environments
                extension_version = chrome.runtime.getManifest().version;
            }
            catch (e) { }
            var trackingInfo = _.info.properties();
            trackingInfo = __assign(__assign({}, trackingInfo), { $extension_version: extension_version });
            return trackingInfo;
        }
    });
    return TrackingInfoGetter;
}());
export { TrackingInfoGetter };
