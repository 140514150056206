import React, { createContext, FC, ReactNode, useContext, useEffect, useRef, useState } from "react";
import { GoinHttpService, Platforms, UserFetcher, User } from "@goinapp/webapp-shared";
import { observer } from "mobx-react-lite";
import { UserStore } from "./UserStore";

type UserContextType = {
  user?: User;
};

const userContextDefaultState = {};
const UserContext = createContext<UserContextType>(userContextDefaultState);

interface UserProviderProps {
  children: ReactNode;
}

const UserProvider: FC<UserProviderProps> = observer((props) => {
  const { children } = props;

  const userStore = useRef(UserStore.getInstance()).current;
  const [loading, setLoading] = useState(true);

  const init = async () => {
    const goinHttpService = GoinHttpService.getInstance();
    await goinHttpService.init(Platforms.BROWSER);
    if (goinHttpService.isAuthorized()) {
      await initUser();
    }
    setLoading(false);
  };

  const initUser = async () => {
    const user = await new UserFetcher().fetch();
    userStore.setUser(user);
  };

  useEffect(() => {
    init();
  }, []);

  return <UserContext.Provider value={{ user: userStore.user }}>{loading ? null : children}</UserContext.Provider>;
});

const useUserContext = () => {
  return useContext(UserContext);
};

export { UserContext, UserProvider, useUserContext };
