import { Money } from "@goinapp/money";
import { RewardOption } from "../RewardOption";
import { ERewardOptionType } from "../ERewardOptionType";
var Giftcard = /** @class */ (function () {
    function Giftcard(id, name, rewardPercentage, logo, country, giftcardUrl, amountLimits, category, denominations) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: id
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: name
        });
        Object.defineProperty(this, "rewardPercentage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: rewardPercentage
        });
        Object.defineProperty(this, "logo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: logo
        });
        Object.defineProperty(this, "country", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: country
        });
        Object.defineProperty(this, "giftcardUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: giftcardUrl
        });
        Object.defineProperty(this, "amountLimits", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: amountLimits
        });
        Object.defineProperty(this, "category", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: category
        });
        Object.defineProperty(this, "denominations", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: denominations
        });
    }
    Object.defineProperty(Giftcard, "fromPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (primitives) {
            return new Giftcard(primitives.id, primitives.name, primitives.rewardPercentage, primitives.logo, primitives.country, primitives.url, primitives.amountLimits, primitives.category, primitives.denominations.map(function (snapshot) { return Money.fromSnapshot(snapshot); }));
        }
    });
    Object.defineProperty(Giftcard.prototype, "mainRewardOption", {
        get: function () {
            return new RewardOption("", ERewardOptionType.PERCENTAGE, this.rewardPercentage);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Giftcard.prototype, "toPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                id: this.id,
                name: this.name,
                rewardPercentage: this.rewardPercentage,
                logo: this.logo,
                country: this.country,
                url: this.giftcardUrl,
                amountLimits: this.amountLimits,
                category: this.category,
                denominations: this.denominations.map(function (money) { return money.snapshot; }),
            };
        }
    });
    Object.defineProperty(Giftcard.prototype, "isShown", {
        get: function () {
            return false;
        },
        enumerable: false,
        configurable: true
    });
    return Giftcard;
}());
export { Giftcard };
