import { ERewardOptionType } from "./ERewardOptionType";
var RewardOption = /** @class */ (function () {
    function RewardOption(name, type, unitAmount // TODO: find better name to represent unit amount and not cents for fix type (btw pholymorfism stuff)
    ) {
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: name
        });
        Object.defineProperty(this, "type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: type
        });
        Object.defineProperty(this, "unitAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: unitAmount
        });
    }
    Object.defineProperty(RewardOption.prototype, "getUnitAmount", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.unitAmount;
        }
    });
    Object.defineProperty(RewardOption.prototype, "getName", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.name;
        }
    });
    Object.defineProperty(RewardOption.prototype, "isAmountGreaterThanZero", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.unitAmount > 0;
        }
    });
    Object.defineProperty(RewardOption.prototype, "isFixedType", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.type === ERewardOptionType.FIX;
        }
    });
    Object.defineProperty(RewardOption.prototype, "isPercentageType", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.type === ERewardOptionType.PERCENTAGE;
        }
    });
    Object.defineProperty(RewardOption, "fromPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (primitives) {
            return new RewardOption(primitives.name, primitives.type, primitives.unitAmount);
        }
    });
    Object.defineProperty(RewardOption.prototype, "toPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                name: this.name,
                type: this.type,
                unitAmount: this.unitAmount,
            };
        }
    });
    return RewardOption;
}());
export { RewardOption };
