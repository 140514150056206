var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { LocalStorageRewardSourceRepository } from "../infrastructure/LocalStorageRewardSourceRepository";
var RewardSourceMatcher = /** @class */ (function () {
    function RewardSourceMatcher() {
        Object.defineProperty(this, "rewardSourceRepository", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new LocalStorageRewardSourceRepository()
        });
    }
    Object.defineProperty(RewardSourceMatcher.prototype, "matches", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (url) {
            return __awaiter(this, void 0, void 0, function () {
                var rewardSourceWebDomain, rewardSources, sortedByUnitAmount;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.findMatches(url)];
                        case 1:
                            rewardSourceWebDomain = _a.sent();
                            if (rewardSourceWebDomain.length === 0) {
                                return [2 /*return*/, null];
                            }
                            return [4 /*yield*/, this.findRewardSources(rewardSourceWebDomain)];
                        case 2:
                            rewardSources = _a.sent();
                            if (rewardSources.length === 0) {
                                return [2 /*return*/, null];
                            }
                            sortedByUnitAmount = this.sortByUnitAmount(rewardSources);
                            return [2 /*return*/, sortedByUnitAmount[0]];
                    }
                });
            });
        }
    });
    Object.defineProperty(RewardSourceMatcher.prototype, "sortByUnitAmount", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (rewardSources) {
            return rewardSources.sort(function (a, b) {
                if (a === null || b === null) {
                    return 0;
                }
                return b.mainRewardOption.getUnitAmount() - a.mainRewardOption.getUnitAmount();
            });
        }
    });
    Object.defineProperty(RewardSourceMatcher.prototype, "findRewardSources", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (rewardSourceWebDomain) {
            return __awaiter(this, void 0, void 0, function () {
                var rewardSources;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, Promise.all(rewardSourceWebDomain.map(function (rewardSourceWebDomain) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.rewardSourceRepository.find(rewardSourceWebDomain.id)];
                                        case 1: return [2 /*return*/, _a.sent()];
                                    }
                                });
                            }); }))];
                        case 1:
                            rewardSources = _a.sent();
                            return [2 /*return*/, rewardSources.filter(function (rewardSource) { return rewardSource !== null; })];
                    }
                });
            });
        }
    });
    Object.defineProperty(RewardSourceMatcher.prototype, "findMatches", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (url) {
            return __awaiter(this, void 0, void 0, function () {
                var webUrl, matchUrl_1, rewardSourceWebDomains, rewardSourceWebDomain, error_1;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            webUrl = new URL(url);
                            matchUrl_1 = this.cleanDomain(webUrl.origin + webUrl.pathname).toLowerCase();
                            return [4 /*yield*/, this.rewardSourceRepository.findWebDomains()];
                        case 1:
                            rewardSourceWebDomains = _a.sent();
                            rewardSourceWebDomain = rewardSourceWebDomains
                                .filter(function (rewardSource) { return !!rewardSource.domain; })
                                .filter(function (rewardSource) {
                                var rewardSourceDomain = _this.cleanDomain(rewardSource.domain).toLowerCase();
                                return matchUrl_1.indexOf(rewardSourceDomain) !== -1;
                            });
                            return [2 /*return*/, rewardSourceWebDomain];
                        case 2:
                            error_1 = _a.sent();
                            return [2 /*return*/, []];
                        case 3: return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(RewardSourceMatcher.prototype, "cleanDomain", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (domain) {
            return domain.replace("www.", "");
        }
    });
    return RewardSourceMatcher;
}());
export { RewardSourceMatcher };
