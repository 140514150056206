import {
  AffiliateOffer,
  CashbackOfferUrlFinder,
  ChromeBus,
  OfferActivatedEvent,
  RewardOptionFormatter,
  RewardSourceFinder,
  TrackEventCommand,
  TrackingInfoGetter,
} from "@goinapp/webapp-shared";
import { action, makeObservable, observable } from "mobx";
import { BrowserExtensionActivateRewardEvent } from "../../shared/events/BrowserExtensionActivateRewardEvent";

export class OfferRedirectPageViewModel {
  private static instance: OfferRedirectPageViewModel;
  private cashbackOfferUrlFinder = new CashbackOfferUrlFinder();
  private rewardSourceFinder = new RewardSourceFinder();
  private TIME_TO_GO_TO_OFFER = 5000;
  private canNavigate = false;
  private timeout?: NodeJS.Timeout;
  private offerId?: string;
  private dest?: string;
  private goinTag?: string;
  private withoutExtensionAuth: boolean = false;

  @observable public offer?: AffiliateOffer;
  @observable public offerUrl?: string;
  private action_source?: string;

  static getInstance() {
    if (!this.instance) {
      this.instance = new OfferRedirectPageViewModel();
    }
    return this.instance;
  }

  constructor() {
    makeObservable(this);
  }

  public async init(id: string, dest: string, goinTag?: string) {
    this.offerId = id;
    this.dest = dest;
    if (!!goinTag) {
      this.goinTag = goinTag;
      this.withoutExtensionAuth = true;
    }
    this.timeout = setTimeout(() => {
      this.canNavigate = true;
      this.navigateToOffer();
    }, this.TIME_TO_GO_TO_OFFER);

    await this.loadOffer();
    await this.loadOfferUrl();
  }

  @action
  private async loadOffer() {
    const offer = await this.rewardSourceFinder.findCashbackOffer(this.offerId!);
    this.setOffer(offer as AffiliateOffer);
  }

  @action
  private async loadOfferUrl() {
    if (this.withoutExtensionAuth) {
      this.offerUrl = await this.cashbackOfferUrlFinder.findByGoinTag(this.offerId!, this.goinTag!);
    } else {
      this.offerUrl = await this.cashbackOfferUrlFinder.find(this.offerId!, this.dest);
    }
    this.navigateToOffer();
  }

  @action
  public async setOffer(offer: AffiliateOffer) {
    this.offer = offer;
  }

  public async directNavigation() {
    clearTimeout(this.timeout);
    this.canNavigate = true;
    this.navigateToOffer();
  }

  public async navigateToOffer() {
    try {
      if (!this.canNavigate || !this.offerUrl) {
        return;
      }
      try {
        if (!this.withoutExtensionAuth) {
          ChromeBus.getInstance().publish(OfferActivatedEvent.$name, new OfferActivatedEvent(this.offerId!).toPrimitives(), {
            toExtension: true,
          });
          await this.trackActivate();
        }
      } catch (err) {
      } finally {
        window.top!.location = this.offerUrl;
      }
    } catch (error) {}
  }

  public getFormattedRewardOption() {
    const reward = this.offer?.mainRewardOption;
    if (!reward) {
      return "%";
    }
    return RewardOptionFormatter.format(reward);
  }

  public setActionSource(action_source: string) {
    this.action_source = action_source;
  }

  private async trackActivate(): Promise<void> {
    await ChromeBus.getInstance().execute(
      TrackEventCommand.$name,
      new TrackEventCommand(
        new BrowserExtensionActivateRewardEvent({
          merchant: this.offer?.name,
          offer_type: this.offer?.type,
          offer_discount_rate: this.offer?.mainRewardOption.getUnitAmount().toString(),
          offer_discount_type: this.offer?.mainRewardOption.toPrimitives().type, // TODO: Smell toPrimitives
          offer_category: this.offer?.category,
          offer_condition: this.offer?.mainRewardOption.getName(),
          action_source: this.action_source,
        }),
        TrackingInfoGetter.get()
      ).toPrimitives(),
      { toExtension: true }
    );
  }
}
