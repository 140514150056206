var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import qs from "qs";
import { Money } from "@goinapp/money";
import { Giftcard } from "../domain/giftcard/Giftcard";
import { GoinHttpService } from "../../../utils";
import { AffiliateOffer } from "../domain/cashback-offer/affiliate-offer/AffiliateOffer";
import { RewardSource } from "../domain/RewardSource";
import { isHttpAffiliatePrimitives, isHttpBankLoyaltyPrimitives, } from "./cashback-offer/IHttpCashbackOfferPrimitives";
import { BankLoyaltyOffer } from "../domain/cashback-offer/bank-loyalty-offer/BankLoyaltyOffer";
import { BankOffer } from "../domain/cashback-offer/bank-offer/BankOffer";
import { RewardSourceWebDomain } from "../domain/RewardSourceWebDomain";
import { RewardSourceId } from "../domain/RewardSourceId";
import { UserFinder } from "../../../user";
var HttpRewardSourceService = /** @class */ (function () {
    function HttpRewardSourceService() {
        Object.defineProperty(this, "rootUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: "/reward/source"
        });
        Object.defineProperty(this, "publicRootUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: "/public/reward/source"
        });
        Object.defineProperty(this, "httpService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: GoinHttpService.getInstance()
        });
        Object.defineProperty(this, "userFinder", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new UserFinder()
        });
        Object.defineProperty(this, "country", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: "es"
        });
    }
    Object.defineProperty(HttpRewardSourceService.prototype, "setCountry", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                var user, country;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, this.userFinder.find()];
                        case 1:
                            user = _b.sent();
                            country = ((_a = user === null || user === void 0 ? void 0 : user.profile) === null || _a === void 0 ? void 0 : _a.country) ? user.profile.country : "es";
                            this.country = country.toLowerCase();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(HttpRewardSourceService.prototype, "findMany", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (criteria) {
            return __awaiter(this, void 0, void 0, function () {
                var queryString, rewardSources_1, gitcards, cashbacks_1, affiliateOffers, bankOffers, rewardSources, giftcards, cashbacks;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.setCountry()];
                        case 1:
                            _a.sent();
                            queryString = qs.stringify(__assign(__assign({}, criteria), { country: this.country }));
                            if (!!this.httpService.isAuthorized()) return [3 /*break*/, 3];
                            return [4 /*yield*/, this.httpService.request({
                                    url: "".concat(this.publicRootUrl, "/many?").concat(queryString),
                                    method: "GET",
                                })];
                        case 2:
                            rewardSources_1 = _a.sent();
                            gitcards = rewardSources_1.data.giftcards.map(function (giftcard) { return RewardSource.newGiftcard(_this.giftcardToDomain(giftcard)); });
                            cashbacks_1 = rewardSources_1.data.cashbacks.map(function (cashback) { return RewardSource.newCashback(_this.cashbackToDomain(cashback)); });
                            affiliateOffers = cashbacks_1.filter(function (cashback) { return cashback.getCashback().isAffiliate(); });
                            bankOffers = cashbacks_1.filter(function (cashback) { return cashback.getCashback().isBank(); });
                            return [2 /*return*/, {
                                    offers: __spreadArray(__spreadArray(__spreadArray([], __read(affiliateOffers), false), __read(gitcards), false), __read(bankOffers), false),
                                    metadata: rewardSources_1.metadata,
                                }];
                        case 3: return [4 /*yield*/, this.httpService.request({
                                url: "".concat(this.rootUrl, "/many?").concat(queryString),
                                method: "GET",
                            })];
                        case 4:
                            rewardSources = _a.sent();
                            giftcards = rewardSources.data.giftcards.map(function (giftcard) { return RewardSource.newGiftcard(_this.giftcardToDomain(giftcard)); });
                            cashbacks = rewardSources.data.cashbacks.map(function (cashback) { return RewardSource.newCashback(_this.cashbackToDomain(cashback)); });
                            return [2 /*return*/, {
                                    offers: __spreadArray(__spreadArray([], __read(cashbacks), false), __read(giftcards), false),
                                    metadata: rewardSources.metadata,
                                }];
                    }
                });
            });
        }
    });
    Object.defineProperty(HttpRewardSourceService.prototype, "findGiftcard", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (slug) {
            return __awaiter(this, void 0, void 0, function () {
                var response;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.setCountry()];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, this.httpService.request({
                                    url: this.httpService.isAuthorized()
                                        ? "".concat(this.rootUrl, "/giftcard/").concat(slug, "/").concat(this.country)
                                        : "".concat(this.publicRootUrl, "/giftcard/").concat(slug, "/").concat(this.country),
                                    method: "GET",
                                })];
                        case 2:
                            response = _a.sent();
                            return [2 /*return*/, this.giftcardToDomain(response.giftcard)];
                    }
                });
            });
        }
    });
    Object.defineProperty(HttpRewardSourceService.prototype, "findCashbackOffer", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (id) {
            return __awaiter(this, void 0, void 0, function () {
                var httpPrimitives;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.httpService.request({
                                url: this.httpService.isAuthorized()
                                    ? "".concat(this.rootUrl, "/cashback-offer/").concat(id)
                                    : "".concat(this.publicRootUrl, "/cashback-offer/").concat(id),
                                method: "GET",
                            })];
                        case 1:
                            httpPrimitives = _a.sent();
                            return [2 /*return*/, this.cashbackToDomain(httpPrimitives)];
                    }
                });
            });
        }
    });
    Object.defineProperty(HttpRewardSourceService.prototype, "findWebDomains", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var httpResponse;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.setCountry()];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, this.httpService.request({
                                    url: "".concat(this.publicRootUrl, "/web-domains/").concat(this.country),
                                    method: "GET",
                                })];
                        case 2:
                            httpResponse = _a.sent();
                            return [2 /*return*/, httpResponse.rewardSources.map(function (source) { return new RewardSourceWebDomain(new RewardSourceId(source.id, source.type), source.domain); })];
                    }
                });
            });
        }
    });
    Object.defineProperty(HttpRewardSourceService.prototype, "cashbackToDomain", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (primitives) {
            var _a, _b, _c;
            if (isHttpAffiliatePrimitives(primitives)) {
                return AffiliateOffer.fromPrimitives({
                    id: primitives.id,
                    type: primitives.type,
                    name: primitives.name,
                    description: primitives.description,
                    category: primitives.category,
                    image: primitives.image,
                    brandLogo: (_a = primitives.brand) === null || _a === void 0 ? void 0 : _a.logo,
                    startDate: primitives.startDate,
                    endDate: primitives.endDate,
                    legalTerms: primitives.legalTerms,
                    applicationChannel: primitives.applicationChannel,
                    applicationUrl: primitives.applicationUrl,
                    active: primitives.active,
                    rewardOptions: !primitives.rewardOptions
                        ? []
                        : primitives.rewardOptions.map(function (rewardOption) {
                            return {
                                name: rewardOption.name || "",
                                type: rewardOption.type,
                                unitAmount: Number(rewardOption.unitAmount) || 0,
                            };
                        }),
                    deepLinkUrl: primitives.deepLinkUrl,
                    mainRewardOption: primitives.mainRewardOption,
                    tieredRewardOptions: primitives.tiersRewardOption || [],
                });
            }
            if (isHttpBankLoyaltyPrimitives(primitives)) {
                return BankLoyaltyOffer.fromPrimitives({
                    id: primitives.id,
                    type: primitives.type,
                    name: primitives.name,
                    description: primitives.description,
                    category: primitives.category,
                    image: primitives.image,
                    brandLogo: (_b = primitives.brand) === null || _b === void 0 ? void 0 : _b.logo,
                    startDate: primitives.startDate,
                    endDate: primitives.endDate,
                    legalTerms: primitives.legalTerms,
                    applicationChannel: primitives.applicationChannel,
                    applicationUrl: primitives.applicationUrl,
                    maxCashbacksPerConsumer: primitives.maxCashbacksPerConsumer,
                    cashbackRate: primitives.cashbackRate,
                    maxAmount: primitives.maxAmount,
                    minAmount: primitives.minAmount,
                    amountToReward: primitives.amountToReward,
                    mainRewardOption: primitives.mainRewardOption,
                    tieredRewardOptions: primitives.tiersRewardOption || [],
                });
            }
            return BankOffer.fromPrimitives({
                id: primitives.id,
                type: primitives.type,
                name: primitives.name,
                description: primitives.description,
                image: primitives.image,
                category: primitives.category,
                brandLogo: (_c = primitives.brand) === null || _c === void 0 ? void 0 : _c.logo,
                startDate: primitives.startDate,
                endDate: primitives.endDate,
                legalTerms: primitives.legalTerms,
                applicationChannel: primitives.applicationChannel,
                applicationUrl: primitives.applicationUrl,
                maxCashbacksPerConsumer: primitives.maxCashbacksPerConsumer,
                cashbackRate: primitives.cashbackRate,
                maxAmount: primitives.maxAmount,
                minAmount: primitives.minAmount,
                mainRewardOption: primitives.mainRewardOption,
                tieredRewardOptions: primitives.tiersRewardOption || [],
            });
        }
    });
    Object.defineProperty(HttpRewardSourceService.prototype, "giftcardToDomain", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (primitives) {
            var _a, _b;
            return Giftcard.fromPrimitives({
                id: primitives.slug,
                name: primitives.name,
                rewardPercentage: ((_a = primitives.cashback) === null || _a === void 0 ? void 0 : _a.goinDiscount) || 0,
                logo: primitives.logoUrl,
                country: primitives.country,
                url: primitives.giftCardUrl,
                amountLimits: {
                    min: primitives.minimumLimit,
                    max: primitives.maximumLimit,
                },
                category: primitives.category,
                denominations: ((_b = primitives.denominations) === null || _b === void 0 ? void 0 : _b.map(function (amount) { return Money.fromCents(amount).snapshot; })) || [],
            });
        }
    });
    return HttpRewardSourceService;
}());
export { HttpRewardSourceService };
