var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Money } from "@goinapp/money";
import { CashbackOffer } from "../CashbackOffer";
import { RewardOption } from "../../RewardOption";
import { TieredRewardOptions } from "../TieredRewardOptions";
var BankOffer = /** @class */ (function (_super) {
    __extends(BankOffer, _super);
    function BankOffer(id, type, name, description, category, image, brandLogo, startDate, endDate, legalTerms, applicationChannel, applicationUrl, maxCashbacksPerConsumer, cashbackRate, maxAmount, minAmount, mainRewardOption, tieredRewardOptions, active, shown) {
        var _this = _super.call(this, id, type, name, description, category, image, brandLogo, startDate, endDate, legalTerms, applicationChannel, applicationUrl, mainRewardOption, tieredRewardOptions, active, shown) || this;
        Object.defineProperty(_this, "maxCashbacksPerConsumer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: maxCashbacksPerConsumer
        });
        Object.defineProperty(_this, "cashbackRate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: cashbackRate
        });
        Object.defineProperty(_this, "maxAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: maxAmount
        });
        Object.defineProperty(_this, "minAmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: minAmount
        });
        return _this;
    }
    Object.defineProperty(BankOffer.prototype, "getRewardOptions", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return [this.mainRewardOption];
        }
    });
    Object.defineProperty(BankOffer.prototype, "storeUrl", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.applicationUrl || "";
        }
    });
    Object.defineProperty(BankOffer.prototype, "getMaximumCashbackToReceive", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return Money.fromNumber(this.maxAmount).multiply(this.cashbackRate / 100);
        }
    });
    Object.defineProperty(BankOffer.prototype, "getMinimumAmount", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return Money.fromNumber(Number(this.minAmount));
        }
    });
    Object.defineProperty(BankOffer.prototype, "hasMaximumCashbackPerUser", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.maxCashbacksPerConsumer !== null;
        }
    });
    Object.defineProperty(BankOffer, "fromPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (primitives) {
            return new BankOffer(primitives.id, primitives.type, primitives.name, primitives.description, primitives.category, primitives.image, primitives.brandLogo, primitives.startDate, primitives.endDate, primitives.legalTerms, primitives.applicationChannel, primitives.applicationUrl, primitives.maxCashbacksPerConsumer, primitives.cashbackRate, primitives.maxAmount, primitives.minAmount, RewardOption.fromPrimitives(primitives.mainRewardOption), TieredRewardOptions.fromPrimitives(primitives.tieredRewardOptions), primitives.active, primitives.shown);
        }
    });
    Object.defineProperty(BankOffer.prototype, "toPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                id: this.id,
                type: this.type,
                name: this.name,
                description: this.description,
                category: this.category,
                image: this.image,
                brandLogo: this.brandLogo,
                startDate: this.startDate,
                endDate: this.endDate,
                legalTerms: this.legalTerms,
                applicationChannel: this.applicationChannel,
                applicationUrl: this.applicationUrl,
                maxCashbacksPerConsumer: this.maxCashbacksPerConsumer,
                cashbackRate: this.cashbackRate,
                maxAmount: this.maxAmount,
                minAmount: this.minAmount,
                mainRewardOption: this.mainRewardOption.toPrimitives(),
                tieredRewardOptions: this.tieredRewardOptions.toPrimitives(),
                active: this.active,
                shown: this.shown,
            };
        }
    });
    return BankOffer;
}(CashbackOffer));
export { BankOffer };
