export var constants = {
    API_URI: process.env.REACT_APP_API_URI || "https://staging-web-api.goin.cloud",
    WEB_URI: process.env.REACT_APP_WEB_URI || "http://localhost:3001",
    MIXPANEL_TOKEN: process.env.REACT_APP_MIXPANEL_TOKEN,
    BRAZE_API_KEY: process.env.REACT_APP_BRAZE_API_KEY,
    SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
    extensionId: {
        chrome: process.env.REACT_APP_CHROME_EXTENSION_ID || "iaoibackikdipoibpmadigjbcmdgdolk",
    },
};
