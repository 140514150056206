var TrackingEvent = /** @class */ (function () {
    function TrackingEvent(eventName, properties, trackingInfo) {
        if (properties === void 0) { properties = {}; }
        Object.defineProperty(this, "eventName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: eventName
        });
        Object.defineProperty(this, "properties", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: properties
        });
        Object.defineProperty(this, "trackingInfo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: trackingInfo
        });
    }
    Object.defineProperty(TrackingEvent.prototype, "toPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                eventName: this.eventName,
                properties: this.properties,
                trackingInfo: this.trackingInfo,
            };
        }
    });
    return TrackingEvent;
}());
export { TrackingEvent };
