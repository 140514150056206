import { ERewardSourceType } from "./ERewardSourceType";
import { RewardSourceId } from "./RewardSourceId";
var RewardSource = /** @class */ (function () {
    function RewardSource(enitity, _id) {
        Object.defineProperty(this, "enitity", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: enitity
        });
        Object.defineProperty(this, "_id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: _id
        });
    }
    Object.defineProperty(RewardSource, "newGiftcard", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (giftcard) {
            return new RewardSource(giftcard, new RewardSourceId(giftcard.id, ERewardSourceType.GIFTCARD));
        }
    });
    Object.defineProperty(RewardSource, "newCashback", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (cashback) {
            return new RewardSource(cashback, new RewardSourceId(cashback.id, ERewardSourceType.CASHBACK));
        }
    });
    Object.defineProperty(RewardSource.prototype, "identifier", {
        get: function () {
            // TODO: review this shit
            return this._id;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RewardSource.prototype, "name", {
        get: function () {
            return this.enitity.name;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RewardSource.prototype, "category", {
        get: function () {
            return this.enitity.category;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RewardSource.prototype, "isShown", {
        get: function () {
            return this.enitity.isShown;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RewardSource.prototype, "isGiftcard", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.identifier.isGiftcard();
        }
    });
    Object.defineProperty(RewardSource.prototype, "isCashback", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.identifier.isCashback();
        }
    });
    Object.defineProperty(RewardSource.prototype, "mainRewardOption", {
        get: function () {
            return this.enitity.mainRewardOption;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RewardSource.prototype, "getGiftcard", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            // TODO: assert isGiftcard ?
            return this.enitity;
        }
    });
    Object.defineProperty(RewardSource.prototype, "getCashback", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            // TODO: assert isCashback ?
            return this.enitity;
        }
    });
    return RewardSource;
}());
export { RewardSource };
