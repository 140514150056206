import { AuthStatus } from "./AuthStatus";
var AuthData = /** @class */ (function () {
    function AuthData(primitives) {
        Object.defineProperty(this, "_id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_token", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_authUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_status", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_authToken", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "_renewToken", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this._id = primitives.id;
        this._token = primitives.token;
        this._authUrl = primitives.authUrl;
        this._status = primitives.status;
        this._authToken = primitives.authToken;
        this._renewToken = primitives.renewToken;
    }
    Object.defineProperty(AuthData, "fromPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (primitives) {
            return new AuthData(primitives);
        }
    });
    Object.defineProperty(AuthData.prototype, "id", {
        get: function () {
            return this._id;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AuthData.prototype, "authUrl", {
        get: function () {
            return this._authUrl;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AuthData.prototype, "authToken", {
        get: function () {
            return this._authToken;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AuthData.prototype, "renewToken", {
        get: function () {
            return this._renewToken;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AuthData.prototype, "isAuthorized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this._status === AuthStatus.AUTHORIZED;
        }
    });
    Object.defineProperty(AuthData.prototype, "toPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                id: this._id,
                token: this._token,
                authUrl: this._authUrl,
                status: this._status,
                authToken: this._authToken,
                renewToken: this._renewToken,
            };
        }
    });
    return AuthData;
}());
export { AuthData };
