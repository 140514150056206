import { CashbackOfferType } from "../../domain/cashback-offer/CashbackOfferType";
export var ApplicationChannel;
(function (ApplicationChannel) {
    ApplicationChannel["ONLINE"] = "ONLINE";
    ApplicationChannel["OFFLINE"] = "OFFLINE";
    ApplicationChannel["BOTH"] = "BOTH";
})(ApplicationChannel || (ApplicationChannel = {}));
export function isHttpAffiliatePrimitives(primitives) {
    return primitives.type === CashbackOfferType.AFFILIATE;
}
export function isHttpBankPrimitives(primitives) {
    return primitives.type === CashbackOfferType.BANK;
}
export function isHttpBankLoyaltyPrimitives(primitives) {
    return primitives.type === CashbackOfferType.BANK_LOYALTY;
}
