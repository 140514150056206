import { AuthData, LoginController, User, ChromeBus, UserLoggedInEvent, UserFetcher } from "@goinapp/webapp-shared";
import { action, computed, makeObservable, observable } from "mobx";
import { UserStore } from "../../shared/user-context/UserStore";

export class LoginPageViewModel {
  private static instance: LoginPageViewModel;
  private loginController = new LoginController();
  private userStore = UserStore.getInstance();

  @observable public user: User | null = null;
  @observable private authData?: AuthData;

  static getInstance() {
    if (!this.instance) {
      this.instance = new LoginPageViewModel();
    }
    return this.instance;
  }

  constructor() {
    makeObservable(this);
  }

  @action
  public setUser(user: User | null) {
    this.user = user;
  }

  public async init(user?: User) {
    if (user) {
      this.setUser(user);
      return;
    }

    const authData = await this.loginController.createSession();
    this.setAuthData(authData);
    const newAuthData = await this.loginController.authorize(authData);
    await this.configureUser();
    this.setAuthData(newAuthData);
    this.notifyLoginEvent(newAuthData);
  }

  private async notifyLoginEvent(authData: AuthData) {
    await ChromeBus.getInstance().publish(UserLoggedInEvent.$name, new UserLoggedInEvent(authData).toPrimitives(), {
      toExtension: true,
    });
  }

  @action
  public setAuthData(authData: AuthData): void {
    this.authData = authData;
  }

  @computed
  public get isAuthorized(): boolean {
    return this.authData?.isAuthorized() || false;
  }

  @computed
  public get qrDeeplink(): string | undefined {
    return this.authData?.authUrl;
  }

  private async configureUser(): Promise<void> {
    const user = await new UserFetcher().fetch();
    this.userStore.setUser(user);
    // TODO: send logged in event to background, remove tracker
  }
}
