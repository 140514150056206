import { DateTime } from "luxon";
import { CashbackOfferType } from "./CashbackOfferType";
var CashbackOffer = /** @class */ (function () {
    // TODO: aggregate here CashbackRewardOption as []
    function CashbackOffer(id, type, name, description, category, image, brandLogo, startDate, endDate, legalTerms, applicationChannel, applicationUrl, mainRewardOption, tieredRewardOptions, active, shown) {
        if (active === void 0) { active = false; }
        if (shown === void 0) { shown = false; }
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: id
        });
        Object.defineProperty(this, "type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: type
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: name
        });
        Object.defineProperty(this, "description", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: description
        });
        Object.defineProperty(this, "category", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: category
        });
        Object.defineProperty(this, "image", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: image
        });
        Object.defineProperty(this, "brandLogo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: brandLogo
        });
        Object.defineProperty(this, "startDate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: startDate
        });
        Object.defineProperty(this, "endDate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: endDate
        });
        Object.defineProperty(this, "legalTerms", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: legalTerms
        });
        Object.defineProperty(this, "applicationChannel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: applicationChannel
        });
        Object.defineProperty(this, "applicationUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: applicationUrl
        });
        Object.defineProperty(this, "mainRewardOption", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: mainRewardOption
        });
        Object.defineProperty(this, "tieredRewardOptions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: tieredRewardOptions
        });
        Object.defineProperty(this, "active", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: active
        });
        Object.defineProperty(this, "shown", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: shown
        });
    }
    Object.defineProperty(CashbackOffer.prototype, "endsInOneMonthOrLess", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (!this.endDate) {
                return false;
            }
            var todayDate = new Date();
            return DateTime.fromISO(this.endDate).diff(DateTime.fromJSDate(todayDate), "days").days <= 30;
        }
    });
    Object.defineProperty(CashbackOffer.prototype, "endsLaterThanAMonth", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return !this.endsInOneMonthOrLess();
        }
    });
    Object.defineProperty(CashbackOffer.prototype, "formattedStartDate", {
        get: function () {
            return this.startDate && DateTime.fromISO(this.startDate).toLocaleString(DateTime.DATE_MED, { locale: navigator.language });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CashbackOffer.prototype, "daysUntilOfferEnds", {
        get: function () {
            return this.endDate && Math.floor(DateTime.fromISO(this.endDate).diffNow("days").days).toString();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CashbackOffer.prototype, "isActive", {
        get: function () {
            return this.active;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CashbackOffer.prototype, "isAffiliate", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.type === CashbackOfferType.AFFILIATE;
        }
    });
    Object.defineProperty(CashbackOffer.prototype, "isBank", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.type === CashbackOfferType.BANK || this.type === CashbackOfferType.BANK_LOYALTY;
        }
    });
    Object.defineProperty(CashbackOffer.prototype, "isBankLoyalty", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.type === CashbackOfferType.BANK_LOYALTY;
        }
    });
    Object.defineProperty(CashbackOffer.prototype, "activate", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.active = true;
        }
    });
    Object.defineProperty(CashbackOffer.prototype, "deactivate", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.active = false;
        }
    });
    Object.defineProperty(CashbackOffer.prototype, "isShown", {
        get: function () {
            return this.shown;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CashbackOffer.prototype, "show", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.shown = false;
        }
    });
    Object.defineProperty(CashbackOffer.prototype, "hide", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.shown = true;
        }
    });
    return CashbackOffer;
}());
export { CashbackOffer };
