import { ERewardSourceType } from "./ERewardSourceType";
var RewardSourceId = /** @class */ (function () {
    function RewardSourceId(value, type) {
        Object.defineProperty(this, "value", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: value
        });
        Object.defineProperty(this, "type", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: type
        });
    }
    Object.defineProperty(RewardSourceId.prototype, "isCashback", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.type === ERewardSourceType.CASHBACK;
        }
    });
    Object.defineProperty(RewardSourceId.prototype, "isGiftcard", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.type === ERewardSourceType.GIFTCARD;
        }
    });
    Object.defineProperty(RewardSourceId.prototype, "toPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                value: this.value,
                type: this.type,
            };
        }
    });
    return RewardSourceId;
}());
export { RewardSourceId };
