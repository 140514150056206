import { User, UserFetcher } from "@goinapp/webapp-shared";
import { action, computed, makeObservable } from "mobx";
import { UserStore } from "../../shared/user-context/UserStore";

export class EnableAutomaticCashbackPageViewModel {
  private static instance: EnableAutomaticCashbackPageViewModel;
  private userFetcher: UserFetcher = new UserFetcher();
  private userStore: UserStore = UserStore.getInstance();

  static getInstance() {
    if (!this.instance) {
      this.instance = new EnableAutomaticCashbackPageViewModel();
    }
    return this.instance;
  }

  constructor() {
    makeObservable(this);
  }

  @computed get user() {
    return this.userStore.user;
  }

  @action setUser(user: User) {
    this.userStore.setUser(user);
  }

  public async startPolling() {
    const intervalAuthorizationMs = 5000;
    do {
      const user: User = await new Promise((resolve) =>
        setTimeout(async () => {
          const user = await this.userFetcher.fetch();

          resolve(user);
        }, intervalAuthorizationMs)
      );
      this.setUser(user);
    } while (!this.user?.hasCashbackAndBankActive);
  }
}
