var BrowserDetector = /** @class */ (function () {
    function BrowserDetector() {
    }
    Object.defineProperty(BrowserDetector, "isChrome", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return /Chrome/.test(navigator.userAgent);
        }
    });
    return BrowserDetector;
}());
export { BrowserDetector };
