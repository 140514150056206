export var ERewardSourceCategory;
(function (ERewardSourceCategory) {
    ERewardSourceCategory["SHOPPING"] = "shopping";
    ERewardSourceCategory["ENTERTAINMENT"] = "entertainment";
    ERewardSourceCategory["FASHION"] = "fashion";
    ERewardSourceCategory["HEALTH"] = "health";
    ERewardSourceCategory["SUPERMARKET"] = "supermarket";
    ERewardSourceCategory["RESTAURANT"] = "restaurant";
    ERewardSourceCategory["TRAVEL"] = "travel";
    ERewardSourceCategory["TECH"] = "tech";
    ERewardSourceCategory["HOME"] = "home";
    ERewardSourceCategory["OTHERS"] = "others";
})(ERewardSourceCategory || (ERewardSourceCategory = {}));
