import { PaymentPlan } from "./PaymentPlan";
var Tier = /** @class */ (function () {
    function Tier(primitives) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "paymentPlans", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "features", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = primitives.id;
        this.name = primitives.name;
        this.paymentPlans = primitives.paymentPlans.map(function (paymentPlan) { return PaymentPlan.fromPrimitives(paymentPlan); });
        this.features = primitives.features;
    }
    Object.defineProperty(Tier, "fromPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (primitives) {
            return new Tier(primitives);
        }
    });
    return Tier;
}());
export { Tier };
