var BrowserCookie = /** @class */ (function () {
    function BrowserCookie() {
    }
    Object.defineProperty(BrowserCookie, "set", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (name, value, options) {
            options = options || {};
            var expires = options.expires;
            if (typeof expires == "number" && expires) {
                var d = new Date();
                d.setTime(d.getTime() + expires * 1000);
                expires = options.expires = d;
            }
            if (expires && expires.toUTCString) {
                options.expires = expires.toUTCString();
            }
            value = encodeURIComponent(value);
            var updatedCookie = name + "=" + value;
            var propName;
            for (propName in options) {
                updatedCookie += "; " + propName;
                var propValue = options[propName];
                if (propValue !== true) {
                    updatedCookie += "=" + propValue;
                }
            }
            document.cookie = updatedCookie;
        }
    });
    Object.defineProperty(BrowserCookie, "get", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (name) {
            var matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"));
            return matches ? decodeURIComponent(matches[1]) : "";
        }
    });
    return BrowserCookie;
}());
export { BrowserCookie };
export function setCookie(name, value, options) {
    BrowserCookie.set(name, value, options);
}
export function getCookie(name) {
    return BrowserCookie.get(name);
}
export function deleteCookie(name) {
    setCookie(name, "", {
        expires: -1,
    });
}
export default BrowserCookie;
