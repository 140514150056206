import { COLORS, Icon, SPACING, Spacing, Text } from "@goinapp/ui";
import { ChromeBus, RefreshUserCommand } from "@goinapp/webapp-shared";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "../../components/QRCode";
import { LOGIN_PAGE } from "../login/LoginPage";
import { EnableAutomaticCashbackPageViewModel } from "./EnableAutomaticCashbackPageViewModel";

export const ENABLE_AUTOMATIC_CASHBACK_PAGE = "EnableAutomaticCashbackPage";
const qrDeeplink = "https://goin.money/cashback/activate";

interface Props {
  navigation: any;
  route: {
    params: {
      merchant_url: string;
    };
  };
}

export const EnableAutomaticCashbackPage = observer((props: Props) => {
  const { t } = useTranslation("web");

  const viewModel = useMemo(() => EnableAutomaticCashbackPageViewModel.getInstance(), []);

  const init = async () => {
    if (!viewModel.user) {
      const redirectParam = { page: ENABLE_AUTOMATIC_CASHBACK_PAGE, params: { merchant_url: props.route.params?.merchant_url } };
      props.navigation.navigate(LOGIN_PAGE, {
        redirect: encodeURIComponent(JSON.stringify(redirectParam)),
      });
    } else {
      // README: Its only to force extensin to reload user, dont remove this line
      await ChromeBus.getInstance().execute(RefreshUserCommand.$name, {}, { toExtension: true });

      if (viewModel.user.hasCashbackAndBankActive) {
        window.top!.location = props.route.params?.merchant_url;
      }
    }
  };

  useEffect(() => {
    init();
  }, [viewModel.user]);

  useEffect(() => {
    viewModel.startPolling();
  }, []);

  return (
    <div className="w-screen h-full flex">
      <div className="hidden xl:block">
        <img className="h-screen object-cover max-w-sm xl:max-w-none" src="/login-decoration.png" alt="Goin" />
      </div>

      <div className="flex-1 flex flex-col">
        <div className="flex-1 flex flex-col items-center min-h-screen justify-center">
          <div className="flex flex-col items-center pt-10">
            <img width={158} height={59} src="/goin.png" alt="Goin" />
            <Spacing vertical="M" />
            <Text.Header4>{t("enableAutomaticCashback.page.title")}</Text.Header4>
            <Text.Header7 style={{ color: COLORS.grayscale50 }}>{t("enableAutomaticCashback.page.subtitle")}</Text.Header7>
          </div>

          <div className="flex gap-16 mt-28 items-center flex-col lg:flex-row">
            <div className="w-96 order-last lg:order-first">
              <div className="flex mb-5 items-center">
                <Icon style={{ justifyContent: "flex-start" }} icon="first" color={COLORS.primary} shape="SIMPLE" size="XL" />
                <Spacing horizontal="XS" />
                <Text.Header6>{t("enableAutomaticCashback.page.instruction.1")}</Text.Header6>
              </div>
              <div className="flex mb-5 items-center">
                <Icon style={{ justifyContent: "flex-start" }} icon="second" color={COLORS.primary} shape="SIMPLE" size="XL" />
                <Spacing horizontal="XS" />
                <Text.Header6>{t("enableAutomaticCashback.page.instruction.2")}</Text.Header6>
              </div>
              <div className="flex mb-5 items-center">
                <Icon style={{ justifyContent: "flex-start" }} icon="third" color={COLORS.primary} shape="SIMPLE" size="XL" />
                <Spacing horizontal="XS" />
                <Text.Header6>{t("enableAutomaticCashback.page.instruction.3")}</Text.Header6>
              </div>
              <div className="flex mb-5 items-center">
                <Icon style={{ justifyContent: "flex-start" }} icon="fourth" color={COLORS.primary} shape="SIMPLE" size="XL" />
                <Spacing horizontal="XS" />
                <Text.Header6>{t("enableAutomaticCashback.page.instruction.4")}</Text.Header6>
              </div>
            </div>
            <div className="w-80 flex items-center flex-col justify-center">
              <div style={{ backgroundColor: COLORS.grayscale96, padding: SPACING.M, borderRadius: SPACING.S }} className="">
                <QRCode data={qrDeeplink} width={224} height={224} />
              </div>

              <div className="text-center w-48 mt-3">
                <Text.Body style={{ color: COLORS.grayscale50 }}>{t("enableAutomaticCashback.page.qr")}</Text.Body>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
