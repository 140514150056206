var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChromeBus } from "./ChromeBus";
var ExternalChromeBus = /** @class */ (function (_super) {
    __extends(ExternalChromeBus, _super);
    function ExternalChromeBus() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ExternalChromeBus, "getQueryBus", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.getInstance();
        }
    });
    Object.defineProperty(ExternalChromeBus, "getCommandBus", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.getInstance();
        }
    });
    Object.defineProperty(ExternalChromeBus, "getInstance", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (!this._instance) {
                this._instance = new ExternalChromeBus();
            }
            return this._instance;
        }
    });
    Object.defineProperty(ExternalChromeBus.prototype, "handle", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (message, sender, sendResponse) {
            var handler = this.handlers[message.topic];
            if (handler) {
                handler({ content: message.content, sender: sender }).then(function (response) {
                    sendResponse(response);
                });
            }
            return true;
        }
    });
    return ExternalChromeBus;
}(ChromeBus));
export { ExternalChromeBus };
