import React, { useEffect, useRef, useState } from "react";
import QRCodeStyling, { Options } from "@goinapp/qr-code";

interface QRProps {
  color?: string;
  gradient?: boolean;
  data: string;
  width: number;
  height: number;
  image?: string;
}

const QRCode = ({ color = "black", gradient = false, data, width, height, image = "/icon-128.png" }: QRProps) => {
  const [options] = useState<Options>({
    id: Math.round(Math.random() * 1000).toFixed(0),
    type: "svg",
    data,
    image,
    width,
    height,
    qrOptions: {
      typeNumber: 0,
      mode: "Byte",
      errorCorrectionLevel: "Q",
    },
    imageOptions: {
      hideBackgroundDots: true,
      imageSize: 0.5,
      margin: 5,
      crossOrigin: "anonymous",
    },
    dotsOptions: {
      color,
      gradient: gradient
        ? {
            type: "linear",
            rotation: 45,
            colorStops: [
              { offset: 0, color: "#D50057" },
              { offset: 1, color: "#FB856A" },
            ],
          }
        : undefined,
      type: "extra-rounded",
    },
    backgroundOptions: {
      color: "transparent",
    },
    cornersSquareOptions: {
      color: gradient ? "#D50057" : color,
      type: "extra-rounded",
    },
    cornersDotOptions: {
      color: gradient ? "#D50057" : color,
      type: "dot",
    },
  });

  const [qrCode] = useState<QRCodeStyling>(new QRCodeStyling(options));
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      qrCode.append(ref.current);
    }
  }, [qrCode, ref]);

  useEffect(() => {
    if (!qrCode) return;
    qrCode.update(options);
  }, [qrCode, options]);

  return <div ref={ref} />;
};

export default QRCode;
