import { RewardOption } from "../RewardOption";
import { TieredRewardOption } from "./TieredRewardOption";
var TieredRewardOptions = /** @class */ (function () {
    function TieredRewardOptions(tieredRewardOptions) {
        Object.defineProperty(this, "tieredRewardOptions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: tieredRewardOptions
        });
    }
    Object.defineProperty(TieredRewardOptions.prototype, "getRewardOptionByTierId", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (tierId) {
            var foundOption = this.tieredRewardOptions.find(function (option) { return option.tierId === tierId; });
            return RewardOption.fromPrimitives(foundOption.rewardOption);
        }
    });
    Object.defineProperty(TieredRewardOptions.prototype, "all", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.tieredRewardOptions.map(function (option) {
                return TieredRewardOption.fromPrimitives(option);
            });
        }
    });
    Object.defineProperty(TieredRewardOptions, "fromPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (primitives) {
            return new TieredRewardOptions(primitives);
        }
    });
    Object.defineProperty(TieredRewardOptions.prototype, "toPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return this.tieredRewardOptions;
        }
    });
    return TieredRewardOptions;
}());
export { TieredRewardOptions };
