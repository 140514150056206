import { fromBEProductMoneyToFEMoney } from "../../money/BEMoneyConverters";
import { FrequencyType } from "./IFrequency";
import { IHighlightType } from "./IHighlight";
var PaymentPlan = /** @class */ (function () {
    function PaymentPlan(id, frequency, amount, amountWithoutDiscount, amountPerInterval, discountPercentage) {
        Object.defineProperty(this, "id", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "frequency", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "amount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "amountWithoutDiscount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "amountPerInterval", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "discountPercentage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "highlight", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.id = id;
        this.frequency = frequency;
        this.amount = fromBEProductMoneyToFEMoney(amount);
        this.amountWithoutDiscount = fromBEProductMoneyToFEMoney(amountWithoutDiscount);
        this.amountPerInterval = fromBEProductMoneyToFEMoney(amountPerInterval);
        this.discountPercentage = discountPercentage;
        this.highlight = this.getHighlight(frequency.type);
    }
    Object.defineProperty(PaymentPlan, "fromPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (primitives) {
            return new PaymentPlan(primitives.id, primitives.frequency, primitives.amount, primitives.amountWithoutDiscount, primitives.amountPerInterval, primitives.discountPercentage);
        }
    });
    Object.defineProperty(PaymentPlan.prototype, "getHighlight", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (frequencyType) {
            if (frequencyType === FrequencyType.ANNUAL) {
                return IHighlightType.BEST_VALUE;
            }
            if (frequencyType === FrequencyType.BIANNUAL) {
                return IHighlightType.MOST_POPULAR;
            }
            return undefined;
        }
    });
    return PaymentPlan;
}());
export { PaymentPlan };
