import { RewardOption } from "../RewardOption";
var TieredRewardOption = /** @class */ (function () {
    function TieredRewardOption(tierId, rewardOption) {
        Object.defineProperty(this, "tierId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: tierId
        });
        Object.defineProperty(this, "rewardOption", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: rewardOption
        });
    }
    Object.defineProperty(TieredRewardOption, "fromPrimitives", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (primitives) {
            return new TieredRewardOption(primitives.tierId, RewardOption.fromPrimitives(primitives.rewardOption));
        }
    });
    return TieredRewardOption;
}());
export { TieredRewardOption };
