var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { constants } from "../../constants/constants";
import { ChromeCookieRepository } from "../cookie-repository/ChromeCookieRepository";
import { AuthController } from "./AuthController";
import { AuthData } from "./AuthData";
import { BrowserCookieRepository } from "../cookie-repository";
import { HttpService } from "./HttpService";
import { HttpStatusCode } from "./HttpStatusCode";
import { LogoutController } from "../../connection";
import { AuthStatus } from "./AuthStatus";
export var Platforms;
(function (Platforms) {
    Platforms["CHROME_EXTENSION"] = "chrome-extension";
    Platforms["BROWSER"] = "browser";
})(Platforms || (Platforms = {}));
var GoinHttpService = /** @class */ (function () {
    function GoinHttpService() {
        Object.defineProperty(this, "httpService", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new HttpService()
        });
        Object.defineProperty(this, "authData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "authController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "logoutController", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new LogoutController()
        });
    }
    Object.defineProperty(GoinHttpService, "getInstance", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (!this.instance) {
                this.instance = new GoinHttpService();
            }
            return this.instance;
        }
    });
    Object.defineProperty(GoinHttpService.prototype, "init", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (platform) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.configureAuthController(platform);
                            return [4 /*yield*/, this.loadAuthData()];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(GoinHttpService.prototype, "loadAuthData", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = this;
                            return [4 /*yield*/, this.authController.find()];
                        case 1:
                            _a.authData = _b.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(GoinHttpService.prototype, "setAuthData", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (authData) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.authData = authData;
                            if (!authData) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.authController.save(authData)];
                        case 1:
                            _a.sent();
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, this.authController.deleteAuth()];
                        case 3:
                            _a.sent();
                            _a.label = 4;
                        case 4: return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(GoinHttpService.prototype, "configureAuthController", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (platform) {
            switch (platform) {
                case Platforms.BROWSER:
                    this.authController = new AuthController(new BrowserCookieRepository());
                    return;
                case Platforms.CHROME_EXTENSION:
                    this.authController = new AuthController(new ChromeCookieRepository());
                    return;
            }
        }
    });
    Object.defineProperty(GoinHttpService.prototype, "isAuthorized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a;
            return !!((_a = this.authData) === null || _a === void 0 ? void 0 : _a.isAuthorized());
        }
    });
    Object.defineProperty(GoinHttpService.prototype, "request", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (config) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, this.authRequest(config)];
                });
            });
        }
    });
    Object.defineProperty(GoinHttpService.prototype, "softRequest", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (config) {
            return __awaiter(this, void 0, void 0, function () {
                var response;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.httpService.request(__assign(__assign({}, config), { url: "".concat(constants.API_URI).concat(config.url) }))];
                        case 1:
                            response = _a.sent();
                            return [2 /*return*/, response.body];
                    }
                });
            });
        }
    });
    Object.defineProperty(GoinHttpService.prototype, "authRequest", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (config, retryCount) {
            if (retryCount === void 0) { retryCount = 3; }
            return __awaiter(this, void 0, void 0, function () {
                var response, tokenRenewed;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (retryCount === 0) {
                                console.error("Request retry count exceeded", config);
                                throw new Error("Request retry count exceeded");
                            }
                            return [4 /*yield*/, this.httpService.request(__assign(__assign({}, config), { url: "".concat(constants.API_URI).concat(config.url), headers: __assign(__assign({}, config.headers), { Authorization: this.authData ? "Bearer ".concat(this.authData.authToken) : undefined }) }))];
                        case 1:
                            response = _a.sent();
                            if (!(response.status === HttpStatusCode.Unauthorized)) return [3 /*break*/, 5];
                            return [4 /*yield*/, this.renewToken()];
                        case 2:
                            tokenRenewed = _a.sent();
                            if (!tokenRenewed) return [3 /*break*/, 4];
                            return [4 /*yield*/, this.authRequest(config, --retryCount)];
                        case 3: return [2 /*return*/, _a.sent()];
                        case 4:
                            this.clearAuthAndUser();
                            _a.label = 5;
                        case 5: return [2 /*return*/, response.body];
                    }
                });
            });
        }
    });
    Object.defineProperty(GoinHttpService.prototype, "clearAuthAndUser", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.authController.deleteAuth()];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, this.logoutController.logout()];
                        case 2:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(GoinHttpService.prototype, "renewToken", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a, _b;
            return __awaiter(this, void 0, void 0, function () {
                var options, response, authData;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            options = {
                                url: "/auth/renew",
                                method: "POST",
                                body: {
                                    authToken: (_a = this.authData) === null || _a === void 0 ? void 0 : _a.authToken,
                                    renewToken: (_b = this.authData) === null || _b === void 0 ? void 0 : _b.renewToken,
                                },
                            };
                            return [4 /*yield*/, this.softRequest(options)];
                        case 1:
                            response = _c.sent();
                            if (response.status === AuthStatus.AUTHORIZED) {
                                authData = AuthData.fromPrimitives(response);
                                this.setAuthData(authData);
                                return [2 /*return*/, true];
                            }
                            else {
                                return [2 /*return*/, false];
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    return GoinHttpService;
}());
export { GoinHttpService };
